// transfer
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'


import { TransfersAssetDetails } from '../../services/srvc-transfers-assets-realm'
import { DispalyLoder, StatusBadge } from '../common/utils'
import WebbDividerSmall from '../webx/webb-divider-sm'


export default function TransfersViewModule (props) {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const { id } = useParams()

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState({})

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        const result = await TransfersAssetDetails({
          data: {
            item: id,
            index: 1,
            items: 25,
            filters: { name: {} }
          }
        })
        if (result.stat) {
          setData(result.data)
          // send data
          props.data(result.data)
        }
        setLoader(false)
      }
      fetchData()
    }
  }, [])

  if (loader) return <DispalyLoder />
  if (!loader && (!data || data.length === 0))
    return <DispalyLoder msg={'Details Not Found'} />

  return (
    <>
      {/* info */}

      {/* asset media */}
      <div className='d-none'>
        <div className='media-banner d-none d-md-block'>
          <img
            src={data?.media?.link} // Route of the image file
            layout='fill'
            className='img-fluid w-100 rounded-none shadow '
            alt='...'
          />
        </div>
        <div className='media-standard d-md-none'>
          <img
            src={data?.media?.link} // Route of the image file
            layout='fill'
            className='img-fluid w-100 rounded-none shadow '
            alt='...'
          />
        </div>
      </div>

      {/* asset details */}
      <div className='back-color-wite p-3 border rounded-xd'>
        <div className='mb-2'></div>

        <p className='text-lead m-0'>Number: {data?.unit?.number || '******'}</p>
        <WebbDividerSmall />

        <div className='d-flex mb-2'>
          <div className='mt-1'>
            <Jazzicon diameter={30} seed={jsNumberForAddress(data?.credit?.item  || Date.now().toString())} /> 
          </div>
          <div className='ms-2'>
            <p className='text-bold m-0'>{data?.credit?.name || '******'}</p>
            <p className='text-small m-0'>{data?.credit?.mail || '******'}</p>            
          </div>
          <div className='ms-auto'>
          <p className='text-color-tone'>
              <i className='bx bx-down-arrow-alt text-icon-sm'></i>
            </p>
          </div>
        </div>

        <div className='d-flex'>
          <div className='mt-1'>
            <Jazzicon diameter={30} seed={jsNumberForAddress(data?.debit?.item  || Date.now().toString())} /> 
          </div>
          <div className='ms-2'>
            <p className='text-bold m-0'>{data?.debit?.name || '******'}</p>
            <p className='text-small m-0'>{data?.debit?.mail || '******'}</p>            
          </div>
          <div className='ms-auto'>
            <p className='text-color-tone'>
              <i className='bx bx-up-arrow-alt text-icon-sm'></i>
            </p>
          </div>
        </div>        

        <div className=''>
          <p className='m-0 mb-1'>Txid: {id || '******'}</p>
          <p className='m-0 mb-1'>To: {data?.credit?.name || '******'}</p>
          <p className='m-0 mb-1'>From: {data?.credit?.mail || '******'}</p>
          <p className='m-0 mb-1'>Asset: {data?.asset?.item || '******'}</p>
          <p className='m-0 mb-1'>To: {data?.credit?.name || '******'}</p>
          <p className='m-0 mb-1'>From: {data?.credit?.mail || '******'}</p>
          <p className='m-0 mb-1'>Unit: {data?.unit?.number || '******'}</p>
          <p className='m-0 mb-1'>
            Status:
            <StatusBadge code={data?.status} />
          </p>
        </div>

        <div className='mb-2 d-none'></div>
      </div>
    </>
  )
}
