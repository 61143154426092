// main
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'

import UserAvatar from '../content/webx/user-avatar'
import WebbIcon from '../content/webx/webb-icon'
import ContentFormat from '../content/webz/content-format-xv'
import WebbHeader from '../content/webz/webb-header-navs-xv'

import WebbDividerMedium from '../content/webx/webb-divider-md'
import WebbDividerSmall from '../content/webx/webb-divider-sm'

import { GetLocalBusiness, GetLocalUser } from '../services/srvc-auth-local'
import { GetUserForm } from '../services/srvc-utilities'

import AssetViewHeaderModule from '../content/assets/assetx-view-header'

import TransfersViewModule from '../content/transfers.assets/transfers-view'

export default function TransfersView () {
  
  const metadata = {
    name: 'Transfer Details',
    banner: {
      link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'
    }
  }

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const { id } = useParams()
  const [data, setData] = useState()
  const [search, setSearch] = useState()

  const handleData = async item => {
    setData(item)
  }

  const dummyFun = async item => {}

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel='canonical' href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: 'xtra', show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`${asset.role}/home`}>
                <WebbIcon
                  data={{ color: 'text-color-main', size: 'text-icon-sm' }}
                />
              </Link>
            </>
          ),
          data: (
            <>
              {' '}
              <WebbHeader />{' '}
            </>
          ),
          footer: (
            <>
              <UserAvatar />
            </>
          )
        }}
        sidebar={{
          header: (
            <>
              <p className='text-normal text-bold m-0'>{metadata.name}</p>
            </>
          ),
          data: (
            <>
              {data?.asset?.item && (
                <AssetViewHeaderModule
                  asset={data?.asset?.item}
                  data={dummyFun}
                />
              )}
            </>
          ),
          footer: <>...</>
        }}
        content={{
          header: (
            <>
              <p className='m-0'>Digital Transfer Details</p>
            </>
          ),
          data: (
            <>
              <h2 className='text-normal text-color-next mb-3'>
                Transfer Details
              </h2>
              <WebbDividerSmall />
              <TransfersViewModule data={handleData} />
              <WebbDividerMedium />
              <h2 className='text-normal text-color-next mb-3'></h2>
              {/* <TransferAssetListAssetModule /> */}

              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          )
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: (
            <>
              {/* <AssetActionsModule /> */}

              <WebbDividerSmall />
              {/* <AssetStatusModule data={data?.status || {}} /> */}
            </>
          ),
          footer: <></>
        }}
      ></ContentFormat>
    </>
  )
}
