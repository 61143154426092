import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";
import { updateTransferData } from "../actions/transferActions";


import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

export default function TransferSearchModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const dispatch = useDispatch();
  const data = useSelector(state => state.transfer);


  const [loader, setLoader] = useState(false);
 

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        // Perform any necessary actions
        setLoader(false);
      };
      fetchData();
    }
  }, []);

  const handleCheckboxChange = (key, val) => {
    let newData;
  
    if (key === "success") {
      newData = { ...data, success: true, pending: false, failed: false, all: false,  page: 1 };
    } else if (key === "pending") {
      newData = { ...data, success: false, pending: true, failed: false, all: false,  page: 1 };
    } else if (key === "all") {
      newData = { ...data, success: false, pending: false, failed: false, all: true,  page: 1 };
    } else if (key === "failed") {
      newData = { ...data, success: false, pending: false, failed: true, all: false,  page: 1 };
    } else if (key === "funds") {
      newData = { ...data, funds:true, page: 1 };
    }
    else if (key === "assets") {
      newData = { ...data, assets:!data.assets, page: 1 };
    }
    else if (key === "start") {
      newData = { ...data, start:val  };
    } 
     else {
      newData = { ...data, [key]: val, page: 1 
    };
    }
      dispatch(updateTransferData(newData));
  };

  return (
    <>
      {/* info */}
      <div className="">Search</div>
  
      {/* dates */}
      <WebbDividerSmall />
      <div className="back-color-wite p-2 px-3 rounded-xd">
        <div className="mb-3">
          <label className="form-label text-small">
            Start Date <FormNeeded />
          </label>
          <input
            type="date"
            className="form-control height-md rounded-wd"
            style={{ fontSize: "0.9rem", height: "2.7rem" }}
            value={data?.start}
            onChange={({ target }) => handleCheckboxChange("start",target.value)}
            disabled={loader}
          ></input>
        </div>
  
        <div className="mb-3">
          <label className="form-label text-small">
            End Date <FormNeeded />
          </label>
          <input
            type="date"
            className="form-control height-md rounded-wd"
            style={{ fontSize: "0.9rem", height: "2.7rem" }}
            value={data?.end}
            onChange={({ target }) => handleCheckboxChange("end",target.value)}
            disabled={loader}
          ></input>
        </div>
      </div>
  
      {/* format */}
      <WebbDividerSmall />
      <div className="back-color-wite p-2 px-3 rounded-xd">
        <div className="">
          <label className="form-label text-small">
            Transfers <FormNeeded />
          </label>
  
          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Funds</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.funds}
                onChange={() => handleCheckboxChange("funds")}
              ></input>
            </div>
          </div>
          <div className="d-flex form-check form-switch m-0 p-0 mb-2 d-none">
            <div className="">
              <p className="m-0 p-0">Assets</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.assets}
                onChange={() => handleCheckboxChange("assets")}
              ></input>
            </div>
          </div>
        </div>
      </div>
  
      {/* status */}
      <WebbDividerSmall />
      <div className="back-color-wite p-2 px-3 rounded-xd">
        <div className="">
          <label className="form-label text-small">
            Status <FormNeeded />
          </label>
  
          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Success</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.success}
                onChange={() => handleCheckboxChange("success",!data.success)}
              ></input>
            </div>
          </div>
  
          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Pending</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.pending}
                onChange={() => handleCheckboxChange("pending",!data.pending)}
              ></input>
            </div>
          </div>
  
          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Failed</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.failed}
                onChange={() => handleCheckboxChange("failed",!data.failed)}
              ></input>
            </div>
          </div>
  
          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">All</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.all}
                onChange={() => handleCheckboxChange("all",!data.all)}
              ></input>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

