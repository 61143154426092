// assets
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { NumberFormat } from '../../services/srvc-utilities'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'
import WebbLoaderMedium from '../webx/webb-loader-md'
import WebbLoaderSmall from '../webx/webb-loader-sm'
import WebbModuleInfo from '../webx/webb-module-info'
import FormNeeded from '../webx/form-needed'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { AssetDetails } from '../../services/srvc-assets-realm'
import { DispalyLoder } from '../common/utils'


const list = [
  { name: 'Total', code: 'count', number: '', ticker: '', active: false },
  { name: 'Mint', code: 'mint', number: '', ticker: '', active: true },
  { name: 'Book', code: 'book', number: '', ticker: '', active: true },
  { name: 'Sale', code: 'sale', number: '', ticker: '', active: true },
  { name: 'Revoke', code: 'burn', number: '', ticker: '', active: true }
]

const media =
  'https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=900'

export default function AssetViewHeaderModule (props) {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const { id } = useParams()

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState()
  const [units, setUnits] = useState()

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        /**
         * here we're using props.asset to re use this component from transfer view page by passing asset id
         * if it recieves props.asset then it will call asset details using passed asset id else
         * it will use from useParams()
         * */
        const result = await AssetDetails({
          data: {
            item: props?.asset || id,
            index: 1,
            items: 25,
            filters: { name: {} }
          }
        })
        if (result.stat) {
          setData(result.data)
          setUnits( Array.from(list, x => { return {...x,number: result.data.units[x.code]}}))

          // send data
          props.data(result.data)
        }

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [])

  if (loader) return <DispalyLoder />

  return (
    <>
      {/* info */}

      {/* asset media */}
      <div className='back-color-wite rounded-xd'>
        <div className='media-standard rounded-xd'>
          <img
            src={data.media.link} // Route of the image file
            layout='fill'
            className='img-fluid w-100 rounded'
            alt='...'
          />
        </div>
      </div>

      {/* asset details */}
      <WebbDividerSmall />
      <div className='back-color-wite rounded-xd p-3'>
        <p className='text-small m-0 mb-1'>
          ID: {data?.webx?.number || '******'}
        </p>
        <h1 className='text-normal m-0 text-bold mb-1'>
          {data?.meta?.name || '******'}
        </h1>
        <p className='m-0'>{data?.meta?.memo || '******'}</p>
        <WebbDividerSmall />

        <div className='d-flex'>
          <div className=''>
            <span className='text-mini'>Created: </span>
            <span className='text-mini'>{new Date(parseInt(data?.created)).toLocaleDateString() || '******'}</span>
          </div>
          <div className='ms-auto'>
            <span className='p-1 px-2 back-color-lite rounded text-mini'>Edit</span>
          </div>
          
        </div>

      </div>

      {/* asset edit */}
      <div className='mb-2'></div>


    </>
  )
}
