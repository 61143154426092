import { Helmet } from 'react-helmet'
import WebbDividerSmall from '../content/webx/webb-divider-sm'
import ContentFormat from '../content/webz/content-format-xx'
import WebbFooterMobile from '../content/webz/webb-footer-mobile'
import WebbHeader from '../content/webz/webb-header-xx'
import { Divider } from '../views.utils/utils'
import TransferUserDetail from "../content/transfers/transfer-detail";


export default function TransferDetailView () {
  const metadata = {
    name: 'Transfers detail',
    banner: {
      link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel='canonical' href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        header={{
          size: 'medium',
          show: true,
          data: (
            <>
              <WebbHeader
                data={{ name: metadata.name, home: '/', link: 'home' }}
              />
            </>
          )
        }}
        media={{ size: 'xtra', show: false, data: <></> }}
        content={{
          size: 'medium',
          show: true,
          data: (
            <>
              <WebbDividerSmall />
              <div className=''>
                <TransferUserDetail/>
              </div>
              <Divider value={6} />
            </>
          )
        }}
        footer={{
          size: 'fluid',
          show: true,
          data: (
            <>
              <div className='text-center back-color-lite'>
                <WebbFooterMobile />
              </div>
            </>
          )
        }}
      />
    </>
  )
}

