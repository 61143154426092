import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { NumberFormat,} from '../../services/srvc-utilities';

export const XLSXFileDownload = (fileData, fileName) => {

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const file = Array.from(fileData, x=> { return {
    created: (new Date(x.created)).toLocaleString(), username: x.user.name, mail: x.user.mail, memo: x.meta.memo, 
    amount: NumberFormat(x.sale.number, "w", "2").toString(),
    ticket:x?.datx?.tckt || '',
    status: x.status == "6"? true: false, reference: x.item , format: x.feat.form, 
  }})

  // rename headers
  const headers = { 
    'created':'Date', 'username':'Name', 'mail':'Mail', 'memo':'Comments', 'amount':'Amount', 'ticket':'Ticket' , 
     'status':'Mint', 'format':'Type', 'reference':'Reference ID',
  };
  file.unshift(headers);

  const ws = XLSX.utils.json_to_sheet(
    file, 
    {
      header:["created","username", "mail" ,"memo","amount", "ticket","status","format","reference",],
      skipHeader:true,
      origin: 'A1'
    }
  );

  const wb = { Sheets: { 'statement': ws }, SheetNames: ['statement'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], {type: fileType});
  
  FileSaver.saveAs(data, fileName + fileExtension);
  
}