import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  updateAssetData,
  updateAssetSearchTerm,
  updateAssetSearchButton,
} from "../actions/assetAction";

import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import { useDispatch, useSelector } from "react-redux";

import { NumberFormat } from "../../services/srvc-utilities";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
const listFormat = require("../../data.static/data-asset-formats.json").data;

export default function AssetsSearchModule() {
  const dispatch = useDispatch();
  const assetSearchData = useSelector((state) => state.asset);

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const data = useSelector((state) => state.asset);

  const navigate = useNavigate();

  const handleCheckboxChange = (key) => {
    let newData = { ...data };

    if (key === "work") {
      if (
        assetSearchData.events === false &&
        assetSearchData.academics === false &&
        assetSearchData.membership === false &&
        assetSearchData.awards === false &&
        assetSearchData.all === false
      ) {
        newData = {
          ...newData,
          work: !assetSearchData.work,
          all: true,
          search: "",
          searchButton: false,
          page: 1,
        };
      } else {
        newData = {
          ...newData,
          work: !assetSearchData.work,
          all: false,
          search: "",
          searchButton: false,
          page: 1,
        };
      }
    } else if (key === "academics") {
      if (
        assetSearchData.events === false &&
        assetSearchData.work === false &&
        assetSearchData.membership === false &&
        assetSearchData.awards === false &&
        assetSearchData.all === false
      ) {
        newData = {
          ...newData,
          academics: !assetSearchData.academics,
          all: true,
          search: "",
          searchButton: false,
          page: 1,
        };
      } else {
        newData = {
          ...newData,
          academics: !assetSearchData.academics,
          all: false,
          search: "",
          searchButton: false,
          page: 1,
        };
      }
    } else if (key === "all") {
      if (
        assetSearchData.events === false &&
        assetSearchData.academics === false &&
        assetSearchData.membership === false &&
        assetSearchData.awards === false &&
        assetSearchData.work === false
      ) {
        newData = {
          ...newData,
          all: true,
          search: "",
          searchButton: false,
          page: 1,
        };
      } else {
        newData = {
          ...newData,
          all: true,
          events: false,
          academics: false,
          membership: false,
          awards: false,
          work: false,
          social: false,
            search: "",
          searchButton: false,
          page: 1,
        };
      }
    } else if (key === "awards") {
      if (
        assetSearchData.events === false &&
        assetSearchData.academics === false &&
        assetSearchData.membership === false &&
        assetSearchData.work === false &&
        assetSearchData.all === false
      ) {
        newData = {
          ...newData,
          awards: !assetSearchData.awards,
          all: true,
          search: "",
          searchButton: false,
          page: 1,
        };
      } else {
        newData = {
          ...newData,
          awards: !assetSearchData.awards,
          all: false,
          search: "",
          searchButton: false,
          page: 1,
        };
      }
    } else if (key === "events") {
      if (
        assetSearchData.work === false &&
        assetSearchData.academics === false &&
        assetSearchData.membership === false &&
        assetSearchData.awards === false &&
        assetSearchData.all === false
      ) {
        newData = {
          ...newData,
          events: !assetSearchData.events,
          all: true,
          search: "",
          searchButton: false,
          page: 1,
        };
      } else {
        newData = {
          ...newData,
          events: !assetSearchData.events,
          search: "",
          all: false,
          searchButton: false,
          page: 1,
        };
      }
    } else if (key === "membership") {
      if (
        assetSearchData.events === false &&
        assetSearchData.academics === false &&
        assetSearchData.work === false &&
        assetSearchData.awards === false &&
        assetSearchData.all === false
      ) {
        newData = {
          ...newData,
          membership: !assetSearchData.membership,
          all: true,
          search: "",
          searchButton: false,
          page: 1,
        };
      } else {
        newData = {
          ...newData,
          membership: !assetSearchData.membership,
          search: "",
          all: false,
          searchButton: false,
          page: 1,
        };
      }
    } else if (key === "live") {
      newData = {
        ...newData,
        live: true,
        draft: false,
        deleted: false,
        search: "",
        searchButton: false,
        page: 1,
      };
    } else if (key === "draft") {
      newData = {
        ...newData,
        live: false,
        draft: true,
        deleted: false,
        search: "",
        searchButton: false,
        page: 1,
      };
    } else if (key === "deleted") {
      newData = {
        ...newData,
        live: false,
        draft: false,
        deleted: true,
        search: "",
        searchButton: false,
        page: 1,
      };
    } else {
      newData = {
        ...newData,
        [key]: !newData[key],
        search: "",
        page: 1,
        searchButton: false,
      };
    }
    dispatch(updateAssetData(newData)); // Dispatch action to update Redux state
  };

  const handleInputChange = (event) => {
    const newSearchTerm = event.target.value;
    dispatch(updateAssetSearchButton(false)); // Dispatch action to update Redux state
    dispatch(updateAssetSearchTerm(newSearchTerm)); // Dispatch action to update Redux state
  };

  const handleSearchClick = () => {
    let newData = { ...data };
    newData = { ...newData, page: 1 };
    dispatch(updateAssetData(newData)); // Dispatch action to update Redux state
    dispatch(updateAssetSearchButton(true)); // Dispatch action to update Redux state
  };

  return (
    <>
      {/* info */}
      <div className="">Search</div>
      <div className="mb-3" style={{ position: "relative" }}>
        <input
          type="text"
          className="form-control mt-3"
          value={data.search}
          onChange={handleInputChange}
        />
        <button
          type="button"
          className="btn btn-lg"
          style={{
            height: "35px",
            width: "48px",
            position: "absolute",
            right: "1px",
            top: "2px",
            padding: "0px",
            zIndex: "10000",

            background: "white",
          }}
          onClick={handleSearchClick}
        >
          <i className="bx bx-search-alt"></i>{" "}
        </button>
      </div>
      {/* format */}
      <WebbDividerSmall />
      <div className="back-color-wite p-2 px-3 rounded-xd">
        <div className="">
          <label className="form-label text-small">
            Formats <FormNeeded />
          </label>

          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Academics</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.academics}
                onChange={() => handleCheckboxChange("academics")}
              ></input>
            </div>
          </div>
          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Work</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.work}
                onChange={() => handleCheckboxChange("work")}
              ></input>
            </div>
          </div>
          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Events</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.events}
                onChange={() => handleCheckboxChange("events")}
              ></input>
            </div>
          </div>
          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Membership</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.membership}
                onChange={() => handleCheckboxChange("membership")}
              ></input>
            </div>
          </div>
          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Awards</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.awards}
                onChange={() => handleCheckboxChange("awards")}
              ></input>
            </div>
          </div>
          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">All</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.all}
                onChange={() => handleCheckboxChange("all")}
              ></input>
            </div>
          </div>
        </div>
      </div>

      {/* status */}
      <WebbDividerSmall />
      <div className="back-color-wite p-2 px-3 rounded-xd d-none">
        <div className="">
          <label className="form-label text-small">
            Status <FormNeeded />
          </label>

          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Live</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.live}
                onChange={() => handleCheckboxChange("live")}
              ></input>
            </div>
          </div>

          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Draft</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.draft}
                onChange={() => handleCheckboxChange("draft")}
              ></input>
            </div>
          </div>

          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Deleted</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.deleted}
                onChange={() => handleCheckboxChange("deleted")}
              ></input>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
