/// navs

export default function NavsButtonNext() {

   
  return (
  <>
    <div className="back-color-wite rounded-xx hidark">
      <span className="btn btn-sm rounded-xx p-2" 
        style={{height:'2.4rem', width:'2.4rem'}}
        >
        <i className="bi bi-chevron-right ms-1" ></i>
      </span>
    </div>
    
  </>
  )
}