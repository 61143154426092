// main
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import ContentFormat from '../content/webz/content-format-xv'
import WebbIcon from '../content/webx/webb-icon'
import WebbHeader from '../content/webz/webb-header-navs-xv'
import UserAvatar from '../content/webx/user-avatar'
import WebbFooterMobile from '../content/webz/webb-footer-mobile'

import WebbDividerMedium from '../content/webx/webb-divider-md'
import WebbDividerSmall from '../content/webx/webb-divider-sm'
import WebbLoaderSmall from '../content/webx/webb-loader-sm'

import { GetUserForm } from '../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../services/srvc-auth-local'

import AssetViewHeaderModule from '../content/assets/assetx-view-header'
// import AssetViewUnitsModule from '../content/assets/assetx-view-statistics'

import AssetHeaderModule from '../content/assets.unit/unitx-header'
import AssetStatisticsModule from '../content/assets/assetx-statistics'

import AssetRatesModule from '../content/assets/assetx-rates'
import AssetUnitsModule from '../content/assets/assetx-units'
import TransferAssetListAssetModule from '../content/transfers.assets/transfers-list-asset'

import AssetActionsModule from '../content/assets/assetx-actions'
import AssetStatusModule from '../content/assets/assetx-status'
import AssetCodeModule from '../content/assets/assetx-code'


export default function AssetsViewCreator () {
  const metadata = {
    name: 'Asset Details',
    banner: {
      link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'
    }
  }

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const { id } = useParams()
  const [data, setData] = useState()
  const [search, setSearch] = useState()

  const handleData = async (item) => {
    // console.log(item)
    setData(item)
  }

  const handleSearch = async (item) => {}

  return (
    <>
      <Helmet>
        <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
        <link rel='canonical' href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: 'xtra', show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`${asset.role}/home`}>
                <WebbIcon data={{ color: 'text-color-main', size: 'text-icon-sm' }}/>
              </Link>
            </>
          ),
          data: ( <>{' '}<WebbHeader />{' '}</> ),
          footer: ( <> <UserAvatar /> </> )
        }}
        sidebar={{
          header: (<> <p className='text-normal text-bold m-0'>{metadata.name}</p> </>),
          data: (
            <> <AssetHeaderModule data={handleData} /> </>
          ),
          footer: <>...</>
        }}
        content={{
          header: ( <> <p className='m-0'>Asset Data</p> </> ),
          data: (
            <>
              <h2 className='text-normal text-color-next mb-3'>Overview</h2>
              <AssetStatisticsModule data={data?.asset || {}}/>
              
              <WebbDividerMedium />
              <h2 className='text-normal text-color-next mb-3'>Audience</h2>
              <AssetRatesModule data={data && data?.rates || []}/>

              <WebbDividerSmall />
              {/* <AssetUnitsModule /> */}

              <WebbDividerMedium />
              <h2 className='text-normal text-color-next mb-3'>Users</h2>
              <TransferAssetListAssetModule data={data?.asset || {}} />

              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          )
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: (
            <>
              <AssetActionsModule data={data?.asset || {}}/>

              <WebbDividerSmall />
              <AssetStatusModule data={data?.status || {}} />

              <WebbDividerSmall />
              <div className=''>
                <AssetCodeModule data={{id: id || ''}}/>
              </div>
              

            </>
          ),
          footer: <></>
        }}
      ></ContentFormat>
    </>
  )
}
