// content
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import FormNeeded from '../webx/form-needed'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { AssetUnits } from '../../services/srvc-assets-realm'
import { AssetDelete } from '../../services/srvc-assets-realm'
import { AssetUnitCreate, AssetUnitDetails } from '../../services/srvc-assets-units-realm'

import { AssetUnitDataMintSet } from '../../services/srvc-assets-units-realm'
import { AssetUnitDataBookSet, AssetUnitDataSaleSet } from '../../services/srvc-assets-units-realm'
import { TeamContactDetails, TeamMemberList } from '../../services/srvc-users-creators-realm'

import { UserAccountDetails } from '../../services/srvc-user-realm'
import { TransfersAssetCreate } from '../../services/srvc-transfers-assets-realm'

import DownloadLink from '../common/DownloadLink'

const listactions = require('../../data.static/data-asset-actionx.json').data

export default function AssetActionsModule (props) {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const { id } = useParams()

  const [loader, setLoader] = useState(true)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)
  
  const [actions, setActions] = useState()
  const [memo, setMemo] = useState('')
  const [units, setUnits] = useState([])
  const [data, setData] = useState({
    mxcount: '',
    mxrate: '',
    mxticker: 'INR',
    txminter: '',
    txminterstatus: false,
    txunit: '',
    txcount: '1'
  })
  const [ticker, setTicker] = useState('INR')
  const [downloadProps, setDownloadProps] = useState({})

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        const result = listactions.filter(x => x.user.includes(asset.role))
        setActions(result)
        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [])

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const datx = { asset: props.data.item, creator: asset.item, index: '1', items: '15' }
        const result = await AssetUnits({ data: datx, srvc: '******' })
        
        if (result.stat) setUnits(result.data.list)
        // console.log(result)
        
        setLoader(false)

      }
      fetchData()
    } else {}
  }, [])


  const handleUnitCreate = async () => {
    setLoader(true)
    setSubmit(true)
    setMemo('Please Wait...')

    const datx = {
      asset: id,
      units: { number: data.mxcount || '0', ticker: 'IAM' },
      rate: { number: data.mxrate || '0', ticker: ticker },
      creator: asset.item
    }
    // console.log(datx)
    const result = await AssetUnitCreate({ data: datx, srvc: '******' })
    // console.log(result)

    if (result.stat) {
      setMemo('Success: Unit Created')
      setDone(true)
    } else {
      setMemo('Error: Unit Not Created')
      setDone(false)
    }
    setLoader(false)
  }
  
  const handleUnitCreateReset = async () => {
    setData({ ...data, mxcount: '', mxrate: '' })
    setSubmit(false)
    setMemo('')
  }

  const handleUnitDataMintset = async () => {
    
    setLoader(true)
    setSubmit(true)
    setMemo('Please Wait...')

    const datx = {
      asset: id,
      unit: units[0]?.item,
      count: data.mxcount || '0',
      mode: 'credit',
      creator: asset.item
    }
    const result = await AssetUnitDataMintSet({ data: datx, srvc: '******' })
    // console.log (result)
    if (result.stat) {
      setMemo('Success: Unit Minted')
      setDone(true)
    } else {
      setMemo('Error: Unit Not Minted')
      setDone(false)
    }

    setSubmit(false)
    setLoader(false)

  }  

  const handleDownload = async () => {
    // const cols = ['username', 'email', 'status']
    // const rows = teamMemberList.map(({ user, role }) => ({
    //   username: user?.name,
    //   email: user?.mail,
    //   status: role
    // }))
    // setDownloadProps({
    //   cols,
    //   rows,
    //   filename: 'asset-users.csv'
    // })
  }

  const handleUnitTransfer = async () => {
    setLoader(true)
    setSubmit(true)
    setMemo('Please Wait...')

    // console.log(data.txminter)
    const userx = await TeamContactDetails({
      data: { user: data.txminter, team: asset.item },
      srvc: '******'
    })
    // console.log(userx)

    if (!userx.stat) {
      setMemo('User not found. Please add to your Network')
      setSubmit(false)
      setLoader(false)
      return
    }
    //asset transfer create
    const datx = {
      user: {
        name: userx?.data?.name,
        mail: userx?.data?.mail
      },
      memo: '',
      asset: id,
      unit: units[0]?.item,
      count: { number: data.txcount, ticker: 'IAM' },
      rate: { number: '0', ticker: 'INR' }
    }
    const result = await TransfersAssetCreate({ 
      data: datx, 
      client: asset?.creds?.client || '******',
      service: asset?.creds?.service || '******'
    })
    // console.log(result)

    if (result.stat) {
      
      var bookx = await AssetUnitDataBookSet({ 
        data: {unit: units[0]?.item, count: data.txcount, mode: 'credit' }, 
        client: asset?.creds?.client || '******',
        service: asset?.creds?.service || '******'
      })

      var salex = await AssetUnitDataSaleSet({ 
        data: {unit: units[0]?.item, count: data.txcount, mode: 'credit' }, 
        client: asset?.creds?.client || '******',
        service: asset?.creds?.service || '******'
      })

      setMemo('Success: Unit Transfered')
      setDone(true)

    } else {
      setMemo('Error: Unit Not Transfered')
      setDone(false)
    }
    setSubmit(false)
    setLoader(false)
  }
  
  const handleUnitTransferReset = async () => {
    setData({
      ...data,
      txminter: '',
      txminterstatus: '',
      txunit: '',
      txcount: '1'
    })
    setSubmit(false)
    setMemo('')
  }

  const handleClick = (live, link) => {
    // if (live) navigate(`/${asset.role}/${link}`)
  }

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }

  // if (loader) return <></>

  return (
    <>
      <div className='back-color-wite rounded-xd p-1'>
        {actions && actions.map((item, i) => item.actv ? (
          <div className={`d-flex p-2 align-middle 
            ${item.live ? 'cursor hidark rounded-wd' : 'text-color-tint'}`}
            style={{ height: '2.4rem' }} key={i}
            data-bs-toggle='modal' data-bs-target={item.link}
          >
            <div className=''>
              <i className={`m-0 p-0 ${item.icon} `} style={{ fontSize: '1.35rem' }}></i>
            </div>
            <div className='ms-2'>
              <p className={`m-0 p-0 text-nowrap`}>
                <span className='text-small align-middle'>{item.name}</span>
              </p>
            </div>
            <div className='ms-auto '>
              <i className={`m-0 p-0 bx bx-chevron-right`} style={{ fontSize: '1.35rem' }}></i>
            </div>
          </div>
        ) : (''))}
      </div>

      {/* action modal */}
      <div
        className='modal fade'
        id='modUnitCreate'
        tabindex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content p-0 m-0 rounded-xd'>
            <div className='modal-header border-none'>
              <p className='text-normal align-middle m-0 p-0'>Create Unit</p>
              <button
                type='button'
                className='btn-close text-small'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => handleUnitCreateReset()}
              ></button>
            </div>

            {/* modal content */}
            <div className='modal-body border-none'>
              <div className='mb-3'>
                <label className='form-label text-small'>
                  Quantity <FormNeeded />
                </label>
                <input
                  type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={data.mxcount}
                  onChange={({ target }) => {
                    handleChange('mxcount', target.value)
                  }}
                  disabled={loader || submit}
                  placeholder='123'
                ></input>
              </div>

              <div className='mb-3'>
                <label className='form-label text-small'>
                  Rate <FormNeeded />
                </label>
                <input
                  type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={data.mxrate}
                  onChange={({ target }) => {
                    handleChange('mxrate', target.value)
                  }}
                  disabled={loader || submit}
                  placeholder='123'
                ></input>
              </div>

              <p className='text-small'>{memo}</p>
            </div>

            <div className='d-flex modal-footer border-none'>
              <div className=''>
                <button
                  className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                  data-bs-dismiss='modal'
                  onClick={() => handleUnitCreateReset()}
                >
                  Cancel
                </button>
              </div>
              <div className='ms-auto'>
                <button
                  className='btn btn-primary btn-sm text-small rounded-xx'
                  onClick={() => handleUnitCreate()}
                  disabled={!data.mxcount || data.mxcount == 0 || submit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* action modal */}
      <div
        className='modal fade'
        id='modUnitMint'
        tabindex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content p-0 m-0 rounded-xd'>
            <div className='modal-header border-none'>
              <p className='text-normal align-middle m-0 p-0'>Mint Unit</p>
              <button
                type='button'
                className='btn-close text-small'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => handleUnitCreateReset()}
              ></button>
            </div>

            {/* modal content */}
            <div className='modal-body border-none'>
              <div className='mb-3'>
                <label className='form-label text-small'>
                  Quantity <FormNeeded />
                </label>
                <input type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={data.mxcount}
                  onChange={({ target }) => { handleChange('mxcount', target.value)}}
                  disabled={loader || submit}
                  placeholder='123'
                ></input>
              </div>

              <div className='mb-3 d-none'>
                <label className='form-label text-small'>Rate <FormNeeded /></label>
                <input type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={data.mxrate}
                  onChange={({ target }) => { handleChange('mxrate', target.value)}}
                  disabled={loader || submit}
                  placeholder='123'
                ></input>
              </div>

              <p className='text-small'>{memo}</p>
            </div>

            <div className='d-flex modal-footer border-none'>
              <div className=''>
                <button
                  className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                  data-bs-dismiss='modal'
                  onClick={() => handleUnitCreateReset()}
                >
                  Cancel
                </button>
              </div>
              <div className='ms-auto'>
                <button
                  className='btn btn-primary btn-sm text-small rounded-xx'
                  onClick={() => handleUnitDataMintset()}
                  disabled={!data.mxcount || data.mxcount == 0 || submit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* action modal */}
      <div
        className='modal fade'
        id='modUnitTransfer'
        tabindex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content p-0 m-0 rounded-xd'>
            <div className='modal-header border-none'>
              <p className='text-normal align-middle m-0 p-0'>New Transfer</p>
              <button
                type='button'
                className='btn-close text-small'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => handleUnitTransferReset()}
              ></button>
            </div>

            {/* modal content */}
            <div className='modal-body border-none'>
              <div className='mb-3'>
                <label className='form-label text-small'>
                  Select Unit <FormNeeded />
                </label>
                <div className={units.length == 1 ? '' : 'd-none'}>
                  <p className=''>{units[0]?.meta?.name || '******'}</p>
                </div>

                <div className={units.length > 1 ? '' : 'd-none'}>
                  <input
                    type='text'
                    className='form-control height-md'
                    style={{ fontSize: '0.9rem', height: '2.7rem' }}
                    value={data.txunit}
                    onChange={({ target }) => {
                      handleChange('txunit', target.value)
                    }}
                    disabled={loader || submit}
                    placeholder='123'
                  ></input>
                </div>
              </div>

              <div className='mb-3'>
                <label className='form-label text-small'>
                  Email <FormNeeded />
                </label>
                <input
                  type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={data.txminter}
                  onChange={({ target }) => {
                    handleChange('txminter', target.value)
                  }}
                  disabled={loader || submit}
                  placeholder='user@bharat.id'
                ></input>
              </div>

              <div className='mb-3'>
                <label className='form-label text-small'>
                  Quantity <FormNeeded />
                </label>
                <input
                  type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={data.txcount}
                  onChange={({ target }) => {
                    handleChange('txcount', target.value)
                  }}
                  disabled={loader || submit || true}
                  placeholder='123'
                ></input>
              </div>

              <div className='mb-3 d-none'>
                <label className='form-label text-small'>
                  Rate <FormNeeded />
                </label>
                <input
                  type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={data.mxrate}
                  onChange={({ target }) => {
                    handleChange('mxrate', target.value)
                  }}
                  disabled={loader || submit}
                  placeholder='123'
                ></input>
              </div>

              <p className='text-small'>{memo}</p>
            </div>

            <div className='d-flex modal-footer border-none'>
              <div className=''>
                <button
                  className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                  data-bs-dismiss='modal'
                  onClick={() => handleUnitTransferReset()}
                >
                  Cancel
                </button>
              </div>
              <div className='ms-auto'>
                <button
                  className='btn btn-primary btn-sm text-small rounded-xx'
                  onClick={() => handleUnitTransfer()}
                  disabled={submit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* action modal */}
      <div
        className='modal fade'
        id='modDataDownload'
        tabindex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog rounded-xd'>
          <div className='modal-content p-0 m-0 rounded-xd'>
            <div className='modal-header border-none'>
              <p className='text-normal align-middle m-0 p-0'>Download Data</p>
              <button
                type='button'
                className='btn-close text-small'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>

            {/* modal content */}
            <div className='modal-body border-none'>
              <DownloadLink download={downloadProps} />
            </div>

            <div className='d-flex modal-footer border-none'>
              <div className=''>
                <button
                  className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                  data-bs-dismiss='modal'
                >
                  Cancel
                </button>
              </div>
              <div className='ms-auto'>
                <button
                  className='btn btn-primary btn-sm text-small rounded-xx'
                  onClick={handleDownload}
                  disabled={data.mxcount == 0 || submit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* action modal */}
      <div
        className='modal fade'
        id='modAssetDelete'
        tabindex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog rounded-xd'>
          <div className='modal-content p-0 m-0 rounded-xd'>
            <div className='modal-header border-none'>
              <p className='text-normal align-middle m-0 p-0'>Delete Asset</p>
              <button
                type='button'
                className='btn-close text-small'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>

            {/* modal content */}
            <div className='modal-body border-none'></div>

            <div className='d-flex modal-footer border-none'>
              <div className=''>
                <button
                  className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                  data-bs-dismiss='modal'
                >
                  Cancel
                </button>
              </div>
              <div className='ms-auto'>
                <button
                  className='btn btn-primary btn-sm text-small rounded-xx'
                  onClick={() => handleUnitCreate()}
                  disabled={data.mxcount == 0 || submit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
