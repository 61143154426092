// init
import axios from 'axios'
import services from '../data.services/data-services-snbx.json'

const base = services.data.find(x => x.code == 'transfers.funds').link

// -----------------

export const TransfersFundCreate = async item => {

  const basx = base + '/transfers/funds/create'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersFundAccountCredit = async item => {

  const basx = base + '/transfers/funds/account/credit'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const DashboardTransfersSummary = async item => {

  const basx = base + '/transfers/funds/dashboard/summary'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersFundAccountDebit = async item => {

  const basx = base + '/transfers/funds/account/debit'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersFundDetails = async item => {
  
  const basx = base + '/transfers/funds/details'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TransfersFundList = async item => {

  const basx = base + '/transfers/funds/list'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TransfersFundListAsset = async item => {

  const basx = base + '/transfers/funds/list/asset'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TransfersGraphStatistics = async (item) => {
    
  const basx = base + '/transfers/graph/summary';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersFundListUnit = async item => {

  const basx = base + '/transfers/funds/list/unit'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersFundListUser = async item => {

  const basx = base + '/transfers/funds/list/users'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TransfersFundListUserWait = async item => {

  const basx = base + '/transfers/funds/list/users/wait'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersFundSubmit = async item => {

  const basx = base + '/transfers/funds/submit'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersFundDecline = async item => {

  const basx = base + '/transfers/funds/decline'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TransfersFundCancel = async item => {

  const basx = base + '/transfers/funds/cancel'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}
