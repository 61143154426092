// transfers
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderMedium from "../webx/webb-loader-md";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { TransfersFundAccountCredit } from "../../services/srvc-transfers-funds-realm";


export default function TransfersAccountCreditModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [form, setForm] = useState(false);
  const [memo, setMemo] = useState('');

  const [data, setData] = useState({
    number: '',
    ticker: 'inr'
  })


  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.number!=='' && data.number!==0 && data.number>98) setForm(true);

  },[data]);


  const handleSubmit = async () => {

    setLoader(true);
    setSubmit(true);
    setMemo('Please wait... 0/1')
    
    const datx = {
      "credit": {
        "name": asset?.name || "******", 
        "mail": asset?.mail || "******",
        "item": asset?.item || "******",
      },
      "debit": {
        "name": asset?.name || "******", 
        "mail": asset?.mail || "******",
        "item": asset?.item || "******",
      },
      "meta": {"name": "account.credit", "memo": "account.credit"},
      "count": {"number": data.number.toString(), "ticker": data.ticker},
      "rate": {"number": "1", "ticker": ""},
      "asset": {},
      "unit": {}
    }

    const result = await TransfersFundAccountCredit({
      data: datx, 
      client: asset?.creds?.client || '******',
      service: asset?.creds?.service || '******'
    })
    // console.log (result)
    
    if (result.stat) {
      setMemo('Please wait... Successs')
      setDone(true)
      setMemo('Successs: Account Credit')
    }
    else {
      setMemo('Please wait... Failed')
    }

    setLoader(false)
    
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  // if (loader) return <></>


  return (
  <>
    {/* info */}
    <div className="mx-3">
      <h2 className='text-normal m-0'>{'Account Credit'}</h2>
      
      <WebbDividerSmall/>
    </div>


    <div className={submit ? 'back-color-wite p-3 rounded-xd' : 'back-color-wite p-3 rounded-xd'}>

      <WebbDividerSmall/>
      <div className={''}>

        <div className="mb-3">  
          <label className="form-label text-small">Enter Amount (Min 99) </label>
          <input type="text" className="form-control height-md"
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            pattern="[1-9][0-9]*"
            value={data.number}
            onChange={({ target }) => { 
              handleChange("number", target.validity.valid 
                ? target.value : data.number ); 
            }}
            disabled={loader || submit}
            placeholder="">
          </input>
        </div>        

      </div>

      
      <div className="">
        <WebbDividerMedium />
        <p className="m-0">{memo}</p>
      </div>
      <div className={loader || submit && !done ? '' : 'd-none'}>
        <div className="mb-2"></div>
        <div className="progress" role="progressbar" aria-label="..." 
          aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" 
          style={{width: "100%", height: '0.5rem'}}
        >
          <div className="progress-bar progress-bar-striped progress-bar-animated" 
            style={{width: "100%", height: '0.5rem'}}
          ></div>
        </div>        
      </div>

      <div className={loader || submit ? 'd-none' : ''}>
        <WebbDividerMedium />
        <div className="d-flex justify-content-between">

        <button className={`btn btn-light border back-color-wite rounded-xx text-small`}
          type="button"
          disabled={loader || submit}
          onClick={()=> { navigate(-1)}}
        >{loader ? 'Please Wait...' : 'Cancel'}</button>

        <button className={`btn btn-primary border-none rounded-xx text-small`}
          type="button"
          disabled={!form || loader || submit}
          onClick={()=> { handleSubmit()}}
        >{loader ? 'Please Wait...' : 'Continue'}</button>

        </div>


      </div>

      <WebbDividerSmall />
    </div>


  </>

  )
}