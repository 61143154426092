// content
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'
import FormNeeded from '../webx/form-needed'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { AssetUnitListMembers } from '../../services/srvc-assets-units-realm'
import { AssetUnitRateSet } from '../../services/srvc-assets-units-realm'

const member0 = {
  meta: {name: 'General', memo: 'General Audience (All)'}, 
  rate: {number: '', ticker: 'inr'}, 
  number: '3356199999990456',
  taxxon: '69c214c90838489b9ecb35383bebe94e6',
  user: '69c214c90838489b9ecb35383bebe94e6',
  active: false,
  item: '69c214c90838489b9ecb35383bebe94e6',
}

export default function UnitRatesModule (props) {
  
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const { id } = useParams()

  const [loader, setLoader] = useState(true)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)

  const [memo, setMemo] = useState('')

  const [members, setMembers] = useState()
  const [rate, setRate] = useState({
    number: '', ticker: ''
  })
  const [data, setData] = useState([]) 

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const datx = {creator: asset.item}
        const result = await AssetUnitListMembers({
          data: datx, 
          client: asset?.creds?.client || '******',
          service: asset?.creds?.service || '******'
        })
        
        // console.log (result.data.list)
        var memberx = [
          member0,
          ...Array.from(result.data.list.filter(z => z.item !== id), x => { return {
          meta: x.meta, 
          rate: {number: '', ticker: 'inr'},
          number: x.number,
          taxxon: x.taxxon,
          user: x.item,
          active: false,
          item: x.item
        }})]

        var memberz = Array.from(memberx, z => { 
          
          var member = props.data.find(x => x.item == z.item) 

          return {...z,
          rate: member?.rate ||  z.rate || {number: '', ticker: 'inr'},
          active: member?.active || z.active || false
        }})
        // console.log (memberz)
        setData(memberz)
        // console.log (props.data)
        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [props])


  const handleRatesMembers = async () => {
  
    var audx = data
    var x = audx.findIndex(z => z.item == members.item)
    audx[x] = {...audx[x], rate: members.rate, active: members.active}
    setData(data => [...audx])

  }

  const handleSubmit = async () => {

    setMemo('Please enter the rates and Submit')
    
    setSubmit(true)
    setLoader(true)

    await handleRatesMembers()

    const datx = {
      unit: id,
      meta: {name: members?.meta?.name, memo: members?.meta?.memo }, 
      rate: members.rate,
      number: members.number, taxxon: members.taxxon, user: members.user,
      active: members.active, item: members.item
    }
    // console.log(datx)
    const result = await AssetUnitRateSet({data: datx, srvc: '******'})
    if (result.stat) {
      setMemo('Success: Rates Set')
      setDone(true)
    } else {
      setMemo('Error')
      setSubmit(false)
      setDone(false)
    }
    setLoader(false)

  }


  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }



  const handleClick = (live, link) => {
    // if (live) navigate(`/${asset.role}/${link}`)
  }

  if (loader) return <>Please Wait...</>
  if (!loader && data.length == 0) return <>No Audience Linked</>

  return (
    <>

      {/* data */}
      <div className={data.length > 0 ? 'back-color-wite border rounded' : 'd-none'}>
        {data && data.map((item, x) => (
        <div className='' key={x}>
          <div className='d-flex p-2 px-3 cursor' 
            data-bs-toggle='modal' data-bs-target={`#modRates`}
            onClick={() => {
              setMembers(item); setRate(item.rate); 
              setMemo('Enter Rates and Submit');
              setSubmit(false); setDone(false)
            }}
          >
            <div className={` ${item.active ? 'text-color-success' : 'text-color-tint'}`}>
              <i className={`bx bxs-circle text-small`}></i>
            </div>
            <div className='ms-2'>
              <p className='m-0'>{item?.meta?.name || '******'}</p>
              <p className='m-0 text-small d-none'>{item?.number || '******'}</p>
            </div>
            <div className='ms-auto text-end'>
              <span className=''>{item?.rate?.number || '******'}</span>
              <span className='ms-1'></span>
              <span className='text-small text-uppercase'>{item?.rate?.ticker || '******'}</span>
            </div>
          </div>
          <div className={x < data.length-1 ? 'border-bottom': ''}></div>
        </div>
        ))}
      </div>


      {/* actions */}
      <div className='modal fade' id='modRates' tabIndex='-1'
        aria-labelledby='exampleModalLabel' aria-hidden='true'
      >
        <div className='modal-dialog rounded-xd'>
          <div className='modal-content p-0 m-0 rounded-xd'>
            <div className='modal-header border-none'>
              <p className='text-normal align-middle m-0 p-0'>Manage Rates</p>
              <button
                type='button'
                className='btn-close text-small'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>

            {/* modal content */}
            <div className='modal-body border-none'>
              <div className=''>
                <p className='text-bold m-0'>{members?.meta?.name || '******'}</p>
                <p className='m-0'>{members?.number || '******'}</p>
              </div>
              <WebbDividerSmall />
              <div className='mb-3'>
                <label className='form-label text-small'>Rate <FormNeeded /></label>
                <input type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={members?.rate?.number}
                  onChange={({ target }) => setMembers({...members, rate: {...rate, number: target.value }})}
                  disabled={loader || submit}
                  placeholder='123'
                ></input>
              </div>

              <div className="d-flex form-check form-switch m-0 p-0">
                <div className="">
                  <p className="m-0 p-0">
                    <span className="align-middle">{'Active'}</span>
                  </p>
                </div>
                <div className="ms-auto text-end">
                  <input className="form-check-input" type="checkbox" 
                    style={{height: '1.2rem', width: '2rem'}}
                    checked={members?.active || false}
                    onChange={() => setMembers({...members, active: !members.active})}>
                  </input>
                </div>          
              </div>

              <WebbDividerMedium />
              <p className='text-small'>{memo}</p>
            </div>

            <div className={!loader && done ? 'd-none' : 'd-flex modal-footer border-none' }>
              <div className=''>
                <button
                  className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                  data-bs-dismiss='modal'
                >Cancel</button>
              </div>
              <div className='ms-auto'>
                <button
                  className='btn btn-primary btn-sm text-small rounded-xx'
                  onClick={() => handleSubmit()}
                  disabled={members?.rate?.number == "" || members?.rate?.number == 0 || submit}
                >Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>          


    </>
  )
}
