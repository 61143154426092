// content
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CSVLink } from 'react-csv'

import WebbDividerMedium from '../webx/webb-divider-md'
import FormNeeded from '../webx/form-needed'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { AssetUnitCreate, AssetUnitDetails } from '../../services/srvc-assets-units-realm'

import { AssetUnitDataMintSet } from '../../services/srvc-assets-units-realm'
import { AssetUnitDataBookSet, AssetUnitDataSaleSet } from '../../services/srvc-assets-units-realm'

import { TransfersAssetCreate } from '../../services/srvc-transfers-assets-realm'
import { TeamContactDetails, TeamMemberList } from '../../services/srvc-users-creators-realm'

import { TransfersAssetListUnit } from '../../services/srvc-transfers-assets-realm'

import { XLSXFileDownload } from '../../content/webx/filesave-xlsx'
import { IndividualOfferCreate } from '../../services/srvc-offers-realm'

const listactions = require('../../data.static/data-units-actionx.json').data

const fileheaders = [
  {label: 'User', key: 'user'},
  {label: 'Email', key: 'Email'},
  {label: 'Date', key: 'Date'},
  {label: 'Status', key: 'Status'},
  {label: 'Amount', key: 'Amount'},
  {label: 'Size', key: 'Size'},
]


export default function UnitActionsModule (props) {
  
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const { id } = useParams()

  const [loader, setLoader] = useState(true)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)
  
  const [actions, setActions] = useState()
  const [memo, setMemo] = useState('')
  const [mail, setMail] = useState('')
  const [rate, setRate] = useState('')
  const [unit, setUnit] = useState('1')

  const [data, setData] = useState({
    mxcount: '',
    mxrate: '',
    mxticker: 'INR',
    txminter: '',
    txminterstatus: false,
    txunit: '',
    txcount: '1'
  })

  

  const [ticker, setTicker] = useState('INR')
  const [download, setDownload] = useState([])

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        const result = listactions.filter(x => x.user.includes(asset.role))
        setActions(result)

        setUnit(props.data)

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [props])


  const handleUnitCreate = async () => {

  }
  
  const handleUnitCreateReset = async () => {
    setData({ ...data, mxcount: '', mxrate: '' })
    setSubmit(false)
    setMemo('')
  }

  const handleUnitDataMintSet = async () => {
    
    setLoader(true)
    setSubmit(true)
    setMemo('Please Wait...')

    const datx = {
      asset: unit?.asset?.item || '',
      unit: id,
      count: data.mxcount || '0',
      mode: 'credit',
      creator: asset.item
    }
    const result = await AssetUnitDataMintSet({ data: datx, srvc: '******' })
    // console.log (result)
    if (result.stat) {
      setMemo('Success: Unit Minted')
      setDone(true)
    } else {
      setMemo('Error: Unit Not Minted')
      setDone(false)
    }

    setSubmit(false)
    setLoader(false)

  } 

  const handleUnitTransfer = async () => {
    setLoader(true)
    setSubmit(true)
    setMemo('Please Wait...')

    //console.log(data.txminter)
    const userx = await TeamContactDetails({
      data: { user: data.txminter, team: asset.item },
      srvc: '******'
    })
    //  console.log(userx)

    if (!userx.stat) {
      setMemo('User not found. Please add to your Network')
      setSubmit(false)
      setLoader(false)
      return
    }
    //asset transfer create
    // var datx = {
    //   credit: {
    //     name: userx?.data?.name,
    //     mail: userx?.data?.mail,
    //     item: userx?.data?.item
    //   },
    //   debit: props?.data?.creator || '',
    //   memo: `${unit.meta.name} ${unit?.webx?.number || '000000000000000'} Transfer ${data?.txcount || 1}`,
    //   asset: unit.asset.item,
    //   unit:  id,
    //   count: { number: data?.txcount || 1, ticker: 'IAM' },
    //   rate: { number: unit?.rates[0]?.rate?.number || '0', ticker: 'INR' },
    //   mode:  'sale',
    //   crxx:  null
    // }
    //console.log (asset)
    // const result = await TransfersAssetCreate({ 
    //   data: datx, 
    //   client: asset?.creds?.client || '******',
    //   service: asset?.creds?.service || '******'
    // })
    // console.log(result)

    // if (result.stat) {
      if(true) {
      
      var bookx = await AssetUnitDataBookSet({ 
        data: {unit: id, count: data.txcount, mode: 'credit' }, 
        client: asset?.creds?.client || '******',
        service: asset?.creds?.service || '******'
      })

      var salex = await AssetUnitDataSaleSet({ 
        data: {unit: id, count: data.txcount, mode: 'credit' }, 
        client: asset?.creds?.client || '******',
        service: asset?.creds?.service || '******'
      })

      // create offer

      var datx = {
        user: {
          name: userx?.data?.name,
          mail: userx?.data?.mail,
          item: userx?.data?.item
        },
        asset: {item: unit.asset.item } ,
        unit:  {item: id },
        creator: [props?.data?.creator],
        cred: null,
        rate: { number: rate || '0', ticker: 'INR' }
      }

      var res = await IndividualOfferCreate({data:datx, service:'', client:''})
      // console.log(res)

      setMemo('Success: Offer Created')
      setDone(true)

    } else {
      setMemo('Error: Invalid Input')
      setDone(false)
    }
    setSubmit(false)
    setLoader(false)
  }
  
  const handleUnitTransferReset = async () => {
    setData({
      ...data,
      txminter: '',
      txminterstatus: '',
      txunit: '',
      txcount: '1'
    })
    setSubmit(false)
    setMemo('')
  }

  const handleDownload = async () => {
    
    setLoader(true)
    setMemo('Please wait...')
    const result = await TransfersAssetListUnit({data: { unit: id, index: '1', items:'9999' }})
    // console.log(result)
    // if (result.stat) setDownload(Array.from(result.data.list, x => { return {user: x.user.name,Email:x.user.mail,Date:x.created,Status:x.status,Amount:x.sale.number,Size:x.size.number}}))
    
    var fileName = `statement-${id}`
    XLSXFileDownload(result.data.list, fileName);

    setLoader(false)
  }



  const handleClick = (live, link) => {
    // if (live) navigate(`/${asset.role}/${link}`)
  }

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }

  return (
    <>
      <div className='back-color-wite rounded-xd p-1'>
        {actions && actions.map((item, i) => item.actv ? (
          <div className={`d-flex p-2 align-middle 
            ${item.live ? 'cursor hidark rounded-wd' : 'text-color-tint'}`}
            style={{ height: '2.4rem' }} key={i}
            data-bs-toggle='modal' data-bs-target={item.link}
          >
            <div className=''>
              <i className={`m-0 p-0 ${item.icon} `} style={{ fontSize: '1.35rem' }}></i>
            </div>
            <div className='ms-2'>
              <p className={`m-0 p-0 text-nowrap`}>
                <span className='text-small align-middle'>{item.name}</span>
              </p>
            </div>
            <div className='ms-auto '>
              <i className={`m-0 p-0 bx bx-chevron-right`} style={{ fontSize: '1.35rem' }}></i>
            </div>
          </div>
        ) : (''))}
      </div>

      {/* action modal */}
      <div
        className='modal fade'
        id='modUnitCreate'
        tabIndex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content p-0 m-0 rounded-xd'>
            <div className='modal-header border-none'>
              <p className='text-normal align-middle m-0 p-0'>Create Unit</p>
              <button
                type='button'
                className='btn-close text-small'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => handleUnitCreateReset()}
              ></button>
            </div>

            {/* modal content */}
            <div className='modal-body border-none'>
              <div className='mb-3'>
                <label className='form-label text-small'>Quantity <FormNeeded /></label>
                <input
                  type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={data.mxcount}
                  onChange={({ target }) => {handleChange('mxcount', target.value)}}
                  disabled={loader || submit}
                  placeholder='123'
                ></input>
              </div>

              <div className='mb-3'>
                <label className='form-label text-small'>
                  Rate <FormNeeded />
                </label>
                <input
                  type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={data.mxrate}
                  onChange={({ target }) => { handleChange('mxrate', target.value) }}
                  disabled={loader || submit}
                  placeholder='123'
                ></input>
              </div>

              <p className='text-small'>{memo}</p>
            </div>

            <div className='d-flex modal-footer border-none'>
              <div className=''>
                <button
                  className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                  data-bs-dismiss='modal'
                  onClick={() => handleUnitCreateReset()}
                >
                  Cancel
                </button>
              </div>
              <div className='ms-auto'>
                <button
                  className='btn btn-primary btn-sm text-small rounded-xx'
                  onClick={() => handleUnitCreate()}
                  disabled={!data.mxcount || data.mxcount == 0 || submit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* action modal */}
      <div
        className='modal fade'
        id='modUnitMint'
        tabIndex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content p-0 m-0 rounded-xd'>
            <div className='modal-header border-none'>
              <p className='text-normal align-middle m-0 p-0'>Mint Unit</p>
              <button
                type='button'
                className='btn-close text-small'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => handleUnitCreateReset()}
              ></button>
            </div>

            {/* modal content */}
            <div className='modal-body border-none'>
              <div className='mb-3'>
                <label className='form-label text-small'>Number of Units <FormNeeded /></label>
                <input type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={data.mxcount}
                  onChange={({ target }) => { handleChange('mxcount', target.value)}}
                  disabled={loader || submit}
                  placeholder='123'
                ></input>
              </div>

              <div className='mb-3 d-none'>
                <label className='form-label text-small'>Rate <FormNeeded /></label>
                <input type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={data.mxrate}
                  onChange={({ target }) => { handleChange('mxrate', target.value)}}
                  disabled={loader || submit}
                  placeholder='123'
                ></input>
              </div>

              <p className='text-small'>{memo}</p>
            </div>

            <div className='d-flex modal-footer border-none'>
              <div className=''>
                <button
                  className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                  data-bs-dismiss='modal'
                  onClick={() => handleUnitCreateReset()}
                >
                  Cancel
                </button>
              </div>
              <div className='ms-auto'>
                <button
                  className='btn btn-primary btn-sm text-small rounded-xx'
                  onClick={() => handleUnitDataMintSet()}
                  disabled={!data.mxcount || data.mxcount == 0 || submit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* action modal */}
      <div className='modal fade' id='modUnitOffer' tabIndex='-1'
        aria-labelledby='exampleModalLabel' aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content p-0 m-0 rounded-xd'>
            <div className='modal-header border-none'>
              <p className='text-normal align-middle m-0 p-0'>Create Offer</p>
              <button
                type='button'
                className='btn-close text-small'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => handleUnitTransferReset()}
              ></button>
            </div>

            {/* modal content */}
            <div className='modal-body border-none'>
              <div className='mb-3 d-none'>
                <label className='form-label text-small'>Select Unit <FormNeeded /></label>
                <div className={''}>
                  <p className=''>{unit?.meta?.name || '******'}</p>
                </div>

                <div className={''}>
                  <input
                    type='text'
                    className='form-control height-md'
                    style={{ fontSize: '0.9rem', height: '2.7rem' }}
                    value={data.txunit}
                    onChange={({ target }) => {handleChange('txunit', target.value)}}
                    disabled={loader || submit}
                    placeholder='123'
                  ></input>
                </div>
              </div>

              <div className='mb-3'>
                <label className='form-label text-small'>Email <FormNeeded /></label>
                <input
                  type='email'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={data.txminter}
                  onChange={({ target }) => {handleChange('txminter', target.value)}}
                  disabled={loader || submit}
                  placeholder='user@bharat.id'
                ></input>
              </div>

              <div className='mb-3'>
                <label className='form-label text-small'>Number of Units <FormNeeded /></label>
                <input
                  type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={data.txcount}
                  onChange={({ target }) => {handleChange('txcount', target.value)}}
                  disabled={loader || submit || true}
                  placeholder='123'
                ></input>
              </div>

              <div className='mb-3 '>
                <label className='form-label text-small'>Rate <FormNeeded /></label>
                <input
                  type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={rate}
                  onChange={({ target }) => {setRate(target.value)}}
                  disabled={loader || submit}
                  placeholder='123'
                ></input>
              </div>

              <p className='text-small'>{memo}</p>
            </div>

            <div className='d-flex modal-footer border-none'>
              <div className=''>
                <button
                  className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                  data-bs-dismiss='modal'
                  onClick={() => handleUnitTransferReset()}
                >
                  Cancel
                </button>
              </div>
              <div className='ms-auto'>
                <button
                  className='btn btn-primary btn-sm text-small rounded-xx'
                  onClick={() => handleUnitTransfer()}
                  disabled={submit || data.txminter == ''}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* action modal */}
      <div className='modal fade' id='modUnitTransfer' tabIndex='-1'
        aria-labelledby='exampleModalLabel' aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content p-0 m-0 rounded-xd'>
            <div className='modal-header border-none'>
              <p className='text-normal align-middle m-0 p-0'>New Transfer</p>
              <button
                type='button'
                className='btn-close text-small'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => handleUnitTransferReset()}
              ></button>
            </div>

            {/* modal content */}
            <div className='modal-body border-none'>
              <div className='mb-3 d-none'>
                <label className='form-label text-small'>Select Unit <FormNeeded /></label>
                <div className={''}>
                  <p className=''>{unit?.meta?.name || '******'}</p>
                </div>

                <div className={''}>
                  <input
                    type='text'
                    className='form-control height-md'
                    style={{ fontSize: '0.9rem', height: '2.7rem' }}
                    value={data.txunit}
                    onChange={({ target }) => {handleChange('txunit', target.value)}}
                    disabled={loader || submit}
                    placeholder='123'
                  ></input>
                </div>
              </div>

              <div className='mb-3'>
                <label className='form-label text-small'>Email <FormNeeded /></label>
                <input
                  type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={data.txminter}
                  onChange={({ target }) => {handleChange('txminter', target.value)}}
                  disabled={loader || submit}
                  placeholder='user@bharat.id'
                ></input>
              </div>

              <div className='mb-3'>
                <label className='form-label text-small'>Number of Units <FormNeeded /></label>
                <input
                  type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={data.txcount}
                  onChange={({ target }) => {handleChange('txcount', target.value)}}
                  disabled={loader || submit || true}
                  placeholder='123'
                ></input>
              </div>

              <div className='mb-3 d-none'>
                <label className='form-label text-small'>Rate <FormNeeded /></label>
                <input
                  type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={data.mxrate}
                  onChange={({ target }) => {handleChange('mxrate', target.value)}}
                  disabled={loader || submit}
                  placeholder='123'
                ></input>
              </div>

              <p className='text-small'>{memo}</p>
            </div>

            <div className='d-flex modal-footer border-none'>
              <div className=''>
                <button
                  className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                  data-bs-dismiss='modal'
                  onClick={() => handleUnitTransferReset()}
                >
                  Cancel
                </button>
              </div>
              <div className='ms-auto'>
                <button
                  className='btn btn-primary btn-sm text-small rounded-xx'
                  onClick={() => handleUnitTransfer()}
                  disabled={submit || data.txminter == ''}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* action modal */}
      <div className='modal fade' id='modDataDownload' tabIndex='-1'
        aria-labelledby='exampleModalLabel' aria-hidden='true'
      >
        <div className='modal-dialog rounded-xd'>
          <div className='modal-content p-0 m-0 rounded-xd'>
            <div className='modal-header border-none'>
              <p className='text-normal align-middle m-0 p-0'>Download Data</p>
              <button
                type='button'
                className='btn-close text-small'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>

            {/* modal content */}
            <div className='modal-body border-none'>
              <span className='p-2 px-3 back-color-next text-color-wite cursor rounded-xd'
                onClick={() => handleDownload()}
              >
                  {loader ? 'Please Wait...' : 'Download Data'}
              </span>

              <WebbDividerMedium />
            </div>

            <div className='d-flex modal-footer border-none d-none'>
              <div className=''>
                <button
                  className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                  data-bs-dismiss='modal'
                >
                  Cancel
                </button>
              </div>
              <div className='ms-auto'>
                <button
                  className='btn btn-primary btn-sm text-small rounded-xx'
                  onClick={handleDownload}
                  disabled={data.mxcount == 0 || submit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* action modal */}
      <div
        className='modal fade'
        id='modAssetDelete'
        tabIndex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog rounded-xd'>
          <div className='modal-content p-0 m-0 rounded-xd'>
            <div className='modal-header border-none'>
              <p className='text-normal align-middle m-0 p-0'>Delete Asset</p>
              <button
                type='button'
                className='btn-close text-small'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>

            {/* modal content */}
            <div className='modal-body border-none'></div>

            <div className='d-flex modal-footer border-none'>
              <div className=''>
                <button
                  className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                  data-bs-dismiss='modal'
                >
                  Cancel
                </button>
              </div>
              <div className='ms-auto'>
                <button
                  className='btn btn-primary btn-sm text-small rounded-xx'
                  onClick={() => handleUnitCreate()}
                  disabled={data.mxcount == 0 || submit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
