// transfers
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import ContentFormat from '../content/webz/content-format-xv'
import UserAvatar from '../content/webx/user-avatar'
import WebbIcon from '../content/webx/webb-icon'
import WebbHeader from '../content/webz/webb-header-navs-xv'

import WebbDividerMedium from '../content/webx/webb-divider-md'

import { GetLocalBusiness, GetLocalUser } from '../services/srvc-auth-local'
import { GetUserForm } from '../services/srvc-utilities'

import TransferSearchModule from '../content/transfers/transfers-search'
import TransfersListMainModule from '../content/transfers/transfers-list-main'

import TransfersFundListModule from '../content/transfers.funds/transfers-list-funds'
import TransfersAssetListModule from '../content/transfers.assets/transfers-list-assets'

import TransfersActionsModule from '../content/transfers/transfers-actions-creator'


export default function Transfers () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx


  const metadata = {
    name: 'Transfers',
    banner: {
      link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'
    }
  }

  return (
    <>
      <Helmet>
        <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
        <link rel='canonical' href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: 'xtra', show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`${asset.role}/home`}>
                <WebbIcon
                  data={{ color: 'text-color-main', size: 'text-icon-sm' }}
                />
              </Link>
            </>
          ),
          data: ( <><WebbHeader /> </>),
          footer: ( <> <UserAvatar /> </> )
        }}
        sidebar={{
          header: ( <> <p className='text-normal text-bold m-0'>{metadata.name}</p> </>),
          data: (
            <> <TransferSearchModule /> </>
          ),
          footer: <>...</>
        }}
        content={{
          header: ( <> <p className='m-0'>Transfers List</p> </> ),
          data: (
            <>
              <TransfersListMainModule />
              
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          )
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: (
            <>
              <TransfersActionsModule />
            </>
          ),
          footer: <></>
        }}
      />
    </>
  )
}
