// assets
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { NumberFormat } from "../../services/srvc-utilities";
import FileCreateCX from "../../services/srvc-filecreate-cweb-xx";

import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import {
  AssetEdit,
  AssetUnitDetails,
} from "../../services/srvc-assets-units-realm";
import { DispalyLoder } from "../common/utils";

const list = [
  { name: "Total", code: "count", number: "", ticker: "", active: false },
  { name: "Mint", code: "mint", number: "", ticker: "", active: true },
  { name: "Book", code: "book", number: "", ticker: "", active: true },
  { name: "Sale", code: "sale", number: "", ticker: "", active: true },
  { name: "Revoke", code: "burn", number: "", ticker: "", active: true },
];

const media =
  "https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=900";

export default function UnitHeaderModule(props) {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();
  const { id } = useParams();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate,     setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [media, setMedia] = useState({ link: '******', mime: '******'})
  const [refresh,setRefresh]=useState(true)
  const [startTime,setStartTime]=useState("")
  const [endTime,setEndTime]=useState("")

  const [emilliseconds,setEmilliseconds]=useState("")
  const [smilliseconds,setSmilliseconds]=useState("")



useEffect(() => {
  
  if(data && data?.dates?.stat != "NaN" && data?.dates?.end != "NaN"){
    setStartDate(new Date(data?.dates?.start).toISOString().substring(0,10));
  setEndDate(new Date(data?.dates?.end).toISOString().substring(0,10));
  }

  const startMilliseconds = data?.dates?.start;
  const sdate = new Date(startMilliseconds);
  const shours = sdate.getHours().toString().padStart(2, '0'); // Ensure two digits
  const sminutes = sdate.getMinutes().toString().padStart(2, '0'); // Ensure two digits
  const sseconds = sdate.getSeconds().toString().padStart(2, '0'); // Ensure two digits
  const sformattedTime = `${shours}:${sminutes}:${sseconds}`;

  const endMilliseconds = data?.dates?.end;
  const edate = new Date(endMilliseconds);
  const ehours = edate.getHours().toString().padStart(2, '0'); // Ensure two digits
  const eminutes = edate.getMinutes().toString().padStart(2, '0'); // Ensure two digits
  const eseconds = edate.getSeconds().toString().padStart(2, '0'); // Ensure two digits
  const eformattedTime = `${ehours}:${eminutes}:${eseconds}`;

  setStartTime(sformattedTime);
  setEndTime(eformattedTime);

},[data]);



  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        const datx = { item: props?.unit || id, creator: asset.item };
        const result = await AssetUnitDetails({
          data: datx,
          service: asset?.creds?.service,
          client:asset?.creds?.client,
          secret:asset?.creds?.secret});
        // console.log (result)  

        if (result.stat) {
          setData(result.data);

          // send data
          props.data(result.data);
        }

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [refresh]);


useEffect(()=>{

const sdateValue = startDate; 
const stimeValue = startTime +":00" 

const [syear, smonth, sday] = sdateValue.split('-');
const [shours, sminutes, sseconds] = stimeValue.split(':');

const scombinedDateTime = new Date(syear, smonth - 1, sday, shours, sminutes, sseconds);

setSmilliseconds( scombinedDateTime.getTime())

const edateValue = endDate; 
const etimeValue = endTime +":00" 

const [eyear, emonth, eday] = edateValue.split('-');
const [ehours, eminutes, eseconds] = etimeValue.split(':');

const ecombinedDateTime = new Date(eyear, emonth - 1, eday, ehours, eminutes, eseconds);

setEmilliseconds(ecombinedDateTime.getTime())
},[startDate,startTime,endDate,endTime])

  const saveEditData = async (item) => {
    // console.log(smilliseconds,emilliseconds)
    var res = await AssetEdit({
      data: {
        asset: id,
        name: name,
        memo: description,
        date: {startDate:smilliseconds.toString() ,endDate  :emilliseconds.toString()},
      },
      service: asset?.creds?.service,client:asset?.creds?.client,secret:asset?.creds?.secret
    });
if(res){
  setRefresh(!refresh)
  setName("");
  setDescription("");
}

  
  };
  const saveImageData = async (item) => {
    var res = await AssetEdit({
      data: {
        asset: id,
       media:media
      },
      service: asset?.creds?.service,client:asset?.creds?.client,secret:asset?.creds?.secret
    });


    if(res){
      setRefresh(!refresh)
      setMedia({ link: '******', mime: '******'})
    }

  };

  const handleSubmit = (item) => {
    saveEditData(item);
  };

  
  const handleSubmitImage = (item) => {
    saveImageData(item);
  };

  const handleMediaBanner = async(filedata) => {
    // console.log (filedata);
    var mediax = {link: filedata?.file.link, mime: filedata?.file?.mime}
    setMedia(media => mediax);
  }


  if (loader) return <DispalyLoder />;

  return (
    // <>
    //   {/* info */}

    //   {/* asset media */}
    //   <div className="back-color-wite rounded-xd" style={{position:"relative"
    //   }}>
    //     <div className="ms-auto" style={{    position: "absolute",
    // zIndex: "1", bottom:"4px" , right:"5px"}}>
    //       <span
    //         className="p-1 px-2 back-color-lite rounded text-mini cursor"
    //         data-bs-toggle="modal"
    //         data-bs-target={`#imageEdit`}
    //       >
    //         Edit
    //       </span>
    //     </div>

    //     <div className="media-standard rounded-xd">
    //       <img
    //         src={data?.media?.link} // Route of the image file
    //         layout="fill"
    //         className="img-fluid w-100 rounded"
    //         alt="..."
    //       />
    //     </div>
    //   </div>

    //   {/* asset details */}
    //   <div className="mb-2"></div>
    //   <div className="back-color-wite rounded-xd p-3">
    //     <p className="m-0 text-bold mb-1">{data?.meta?.name || "******"}</p>
    //     <p className="text-small m-0">{data?.meta?.memo || "******"}</p>
    //     <WebbDividerSmall />

    //     <div className="d-flex">
    //       <div className="">
    //         <span className="text-mini">Created: </span>
    //         <span className="text-mini">
    //           {new Date(parseInt(data?.created)).toLocaleDateString() ||
    //             "******"}
    //         </span>
    //       </div>
    //       <div className="ms-auto">
    //         <span
    //           className="p-1 px-2 back-color-lite rounded text-mini cursor"
    //           data-bs-toggle="modal"
    //           data-bs-target={`#modEdit`}
    //         >
    //           Edit
    //         </span>
    //       </div>
    //       <div
    //         className="modal fade"
    //         id="modEdit"
    //         tabIndex="-1"
    //         aria-labelledby="exampleModalLabel"
    //         aria-hidden="true"
    //       >
    //         <div className="modal-dialog rounded-xd">
    //           <div className="modal-content p-0 m-0 rounded-xd">
    //             <div className="modal-header border-none">
    //               <p className="text-normal align-middle m-0 p-0">Edit</p>
    //               <button
    //                 type="button"
    //                 className="btn-close text-small"
    //                 data-bs-dismiss="modal"
    //                 aria-label="Close"
    //               ></button>
    //             </div>

    //             {/* modal content */}
    //             <div className="modal-body border-none">
    //               <WebbDividerSmall />
    //               <div className="mb-3">
    //                 <label className="form-label text-small">
    //                   Name <FormNeeded />
    //                 </label>
    //                 <input
    //                   type="text"
    //                   className="form-control height-md"
    //                   style={{ fontSize: "0.9rem", height: "2.7rem" }}
    //                   defaultValue={data?.meta?.name}
    //                   onChange={({ target }) => setName(target.value)}
    //                   // disabled={loader || submit}
    //                   // placeholder="123"
    //                 ></input>
    //               </div>
    //               <div className="mb-3">
    //                 <label className="form-label text-small">
    //                   Description <FormNeeded />
    //                 </label>
    //                 <textarea
    //                   className="form-control height-md"
    //                   style={{ fontSize: "0.9rem", height: "100px" }}
    //                   defaultValue={data?.meta?.memo}
    //                   onChange={({ target }) => setDescription(target.value)}
    //                 ></textarea>
    //               </div>

                  

    //       <div className={`d-flex mb-3 gap-2 `}>
    //       <div className="w-50" >
    //         <label className="form-label text-small">Start Date <FormNeeded/></label>
    //         <input type="date" className="form-control height-md"
    //           style={{fontSize:'0.9rem', height:'2.7rem'}}
    //           defaultValue={(new Date(data?.dates?.start)).toISOString().substring(0,10)}
    //           onChange={({ target }) => setStartDate(target.value)}
    //           placeholder="">
    //         </input>
    //       </div>
    //       <div className="w-50" >
    //         <label className="form-label text-small">Start Time <FormNeeded/></label>
    //         <input type="time" className="form-control height-md"
    //           style={{fontSize:'0.9rem', height:'2.7rem'}}
    //           value={startTime}
    //           onChange={({ target }) => {setStartTime(target.value)}}
    //           placeholder="">
    //         </input>
    //       </div>
          
    //     </div>

    //     <div className={`d-flex mb-3 gap-2`}>
    //       <div className="w-50">
    //         <label className="form-label text-small">End Date <FormNeeded/></label>
    //         <input type="date" className="form-control height-md"
    //           style={{fontSize:'0.9rem', height:'2.7rem'}}
    //           defaultValue={(new Date(data?.dates?.end)).toISOString().substring(0,10)}
    //           onChange={({ target }) => setEndDate(target.value)}
    //           placeholder="">
    //         </input>
    //       </div>
    //       <div className="w-50">
    //         <label className="form-label text-small">End Time <FormNeeded/></label>
    //         <input type="time" className="form-control height-md"
    //           style={{fontSize:'0.9rem', height:'2.7rem'}}
    //           value={endTime}
    //           onChange={({ target }) => {setEndTime(target.value)}}
    //           placeholder="">
    //         </input>
    //       </div>
    //     </div>

    //             </div>

    //             <div className="d-flex modal-footer border-none">
    //               <div className="">
    //                 <button
    //                   className="btn btn-outline-secondary btn-sm text-small rounded-xx"
    //                   data-bs-dismiss="modal"
    //                 >
    //                   Cancel
    //                 </button>
    //               </div>
    //               <div className="ms-auto">
    //                 <button
    //                   className="btn btn-primary btn-sm text-small rounded-xx"
    //                   onClick={() => handleSubmit()}
    //                   // disabled={members?.rate?.number == "" || members?.rate?.number == 0 || submit}
    //                 >
    //                   Submit
    //                 </button>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div
    //         className="modal fade"
    //         id="imageEdit"
    //         tabIndex="-1"
    //         aria-labelledby="exampleModalLabel"
    //         aria-hidden="true"
    //       >
    //         <div className="modal-dialog rounded-xd">
    //           <div className="modal-content p-0 m-0 rounded-xd">
    //             <div className="modal-header border-none">
    //               <p className="text-normal align-middle m-0 p-0">
    //                 Change image <FormNeeded />
    //               </p>
    //               <button
    //                 type="button"
    //                 className="btn-close text-small"
    //                 data-bs-dismiss="modal"
    //                 aria-label="Close"
    //               ></button>
    //             </div>

    //             {/* modal content */}
    //            <div className="p-3">
    //            <FileCreateCX
    //               size="media-standard" media={handleMediaBanner}
    //             />
    //            </div>
                

    //             <div className="d-flex modal-footer border-none">
    //               <div className="">
    //                 <button
    //                   className="btn btn-outline-secondary btn-sm text-small rounded-xx"
    //                   data-bs-dismiss="modal"
    //                 >
    //                   Cancel
    //                 </button>
    //               </div>
    //               <div className="ms-auto"    disabled={media.link == '******'}>
    //                 <button
    //                   className="btn btn-primary btn-sm text-small rounded-xx"
    //                   onClick={() => handleSubmitImage()}
    //                   disabled={media.link == '******'}
    //                 >
    //                   Submit
    //                 </button>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   {/* asset edit */}
    //   <div className="mb-2"></div>
    //   <div className="mb-2 mx-3">
    //     <p className="text-mini m-0 mb-1">
    //       ID: {data?.webx?.number || "******"}
    //     </p>
    //   </div>

    //   <div className=" my-2">
    //     <div className="bg-white my-2  p-3  rounded">
    //       <p className="fw-bold">Date</p>
    //       <div className="d-flex  gap-4  justify-content-between">
    //         <p> Start</p>
    //         <p
    //           style={{
    //             minWidth: "50px",
    //             marginLeft: "40px",
    //             wordWrap: "break-word",
    //           }}
    //         >
    //               {new Date(data?.dates?.start).toLocaleString(undefined, { hour12: true })}

    //         </p>
    //       </div>{" "}
    //       <div className="d-flex  gap-4  justify-content-between">
    //         <p> End</p>
    //         <p
    //           style={{
    //             minWidth: "50px",
    //             marginLeft: "40px",
    //             wordWrap: "break-word",
    //           }}
    //         >
    //               {new Date(data?.dates?.end).toLocaleString(undefined, { hour12: true })}
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </>

    <>
    {/* info */}

    {/* asset media */}
    <div className="back-color-wite rounded-xd" style={{position:"relative"
    }}>
      <div className="ms-auto" style={{    position: "absolute",
  zIndex: "1", bottom:"4px" , right:"5px"}}>
        <span
          className="p-1 px-2 back-color-lite rounded text-mini cursor"
          data-bs-toggle="modal"
          data-bs-target={`#imageEdit`}
        >
          Edit
        </span>
      </div>

      <div className="media-standard rounded-xd">
        <img
          src={data?.media?.link} // Route of the image file
          layout="fill"
          className="img-fluid w-100 rounded"
          alt="..."
        />
      </div>
    </div>

    {/* asset details */}
    <div className="mb-2"></div>
    <div className="back-color-wite rounded-xd p-3">
      <p className="m-0 text-bold mb-1">{data?.meta?.name || "******"}</p>
      <p className="text-small m-0">{data?.meta?.memo || "******"}</p>
      <WebbDividerSmall />

      <div className="d-flex">
        <div className="">
          <span className="text-mini">Created: </span>
          <span className="text-mini">
            {new Date(parseInt(data?.created)).toLocaleDateString() ||
              "******"}
          </span>
        </div>
        <div className="ms-auto">
          <span
            className="p-1 px-2 back-color-lite rounded text-mini cursor"
            data-bs-toggle="modal"
            data-bs-target={`#modEdit`}
          >
            Edit
          </span>
        </div>
        <div
          className="modal fade"
          id="modEdit"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog rounded-xd">
            <div className="modal-content p-0 m-0 rounded-xd">
              <div className="modal-header border-none">
                <p className="text-normal align-middle m-0 p-0">Edit</p>
                <button
                  type="button"
                  className="btn-close text-small"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              {/* modal content */}
              <div className="modal-body border-none">
                <WebbDividerSmall />
                <div className="mb-3">
                  <label className="form-label text-small">
                    Name <FormNeeded />
                  </label>
                  <input
                    type="text"
                    className="form-control height-md"
                    style={{ fontSize: "0.9rem", height: "2.7rem" }}
                    defaultValue={data?.meta?.name}
                    onChange={({ target }) => setName(target.value)}
                    // disabled={loader || submit}
                    // placeholder="123"
                  ></input>
                </div>
                <div className="mb-3">
                  <label className="form-label text-small">
                    Description <FormNeeded />
                  </label>
                  <textarea
                    className="form-control height-md"
                    style={{ fontSize: "0.9rem", height: "100px" }}
                    defaultValue={data?.meta?.memo}
                    onChange={({ target }) => setDescription(target.value)}
                  ></textarea>
                </div>

                
        {
          data?.dates?.start != "NaN" ?
          <>
          <div className={`d-flex mb-3 gap-2 `}>
            <div className="w-50" >
              <label className="form-label text-small">Start Date <FormNeeded/></label>
              <input type="date" className="form-control height-md"
                style={{fontSize:'0.9rem', height:'2.7rem'}}
                defaultValue={(new Date(data?.dates?.start)).toISOString().substring(0,10) }
                onChange={({ target }) => setStartDate(target.value)}
                placeholder="">
              </input>
            </div>
            <div className="w-50" >
              <label className="form-label text-small">Start Time <FormNeeded/></label>
              <input type="time" className="form-control height-md"
                style={{fontSize:'0.9rem', height:'2.7rem'}}
                value={startTime}
                onChange={({ target }) => {setStartTime(target.value)}}
                placeholder="">
              </input>
            </div> 
          </div>
          </>
          :
          <>
          </>
        }

        
        {

          data?.dates?.end != "NaN" ?
          <>
          <div className={`d-flex mb-3 gap-2`}>
            <div className="w-50">
              <label className="form-label text-small">End Date <FormNeeded/></label>
              <input type="date" className="form-control height-md"
                style={{fontSize:'0.9rem', height:'2.7rem'}}
                defaultValue={(new Date(data?.dates?.end)).toISOString().substring(0,10)}
                onChange={({ target }) => setEndDate(target.value)}
                placeholder="">
              </input>
            </div>
            <div className="w-50">
              <label className="form-label text-small">End Time <FormNeeded/></label>
              <input type="time" className="form-control height-md"
                style={{fontSize:'0.9rem', height:'2.7rem'}}
                value={endTime}
                onChange={({ target }) => {setEndTime(target.value)}}
                placeholder="">
              </input>
            </div>
          </div>
          </>
          :
          <>
          </>

        }

      

              </div>

              <div className="d-flex modal-footer border-none">
                <div className="">
                  <button
                    className="btn btn-outline-secondary btn-sm text-small rounded-xx"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
                <div className="ms-auto">
                  <button
                    className="btn btn-primary btn-sm text-small rounded-xx"
                    onClick={() => handleSubmit()}
                    // disabled={members?.rate?.number == "" || members?.rate?.number == 0 || submit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="imageEdit"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog rounded-xd">
            <div className="modal-content p-0 m-0 rounded-xd">
              <div className="modal-header border-none">
                <p className="text-normal align-middle m-0 p-0">
                  Change image <FormNeeded />
                </p>
                <button
                  type="button"
                  className="btn-close text-small"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              {/* modal content */}
             <div className="p-3">
             <FileCreateCX
                size="media-standard" media={handleMediaBanner}
              />
             </div>
              

              <div className="d-flex modal-footer border-none">
                <div className="">
                  <button
                    className="btn btn-outline-secondary btn-sm text-small rounded-xx"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
                <div className="ms-auto"    disabled={media.link == '******'}>
                  <button
                    className="btn btn-primary btn-sm text-small rounded-xx"
                    onClick={() => handleSubmitImage()}
                    disabled={media.link == '******'}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* asset edit */}
    <div className="mb-2"></div>
    <div className="mb-2 mx-3">
      <p className="text-mini m-0 mb-1">
        ID: {data?.webx?.number || "******"}
      </p>
    </div>

    <div className=" my-2">
      <div className="bg-white my-2  p-3  rounded">
        <p className="fw-bold">Date</p>
        <div className="d-flex  gap-4  justify-content-between">
          <p> Start</p>
          <p
            style={{
              minWidth: "50px",
              marginLeft: "40px",
              wordWrap: "break-word",
            }}
          >
                { data?.dates?.start != "NaN" ? new Date(data?.dates?.start).toLocaleString(undefined, { hour12: true }) : ''}

          </p>
        </div>{" "}
        <div className="d-flex  gap-4  justify-content-between">
          <p> End</p>
          <p
            style={{
              minWidth: "50px",
              marginLeft: "40px",
              wordWrap: "break-word",
            }}
          >
                { data?.dates?.end != "NaN" ? new Date(data?.dates?.end).toLocaleString(undefined, { hour12: true }) : ''}
          </p>
        </div>
      </div>
    </div>
  </>
  );
}
