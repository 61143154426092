import { Helmet } from 'react-helmet'
import WebbDividerSmall from '../content/webx/webb-divider-sm'
import ContentFormat from '../content/webz/content-format-xw'
import WebbFooterMobile from '../content/webz/webb-footer-mobile'
import WebbHeader from '../content/webz/webb-header-xw'
import { Divider } from '../views.utils/utils'

export default function HomeUser () {
  const metadata = {
    name: 'Home',
    banner: {
      link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel='canonical' href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        header={{
          size: 'medium',
          show: true,
          data: (
            <>
              <WebbHeader data={{ name: metadata.name, home: '/', link: '' }} />
            </>
          )
        }}
        media={{ size: 'fluid', show: false, data: <></> }}
        content={{
          size: 'medium',
          show: true,
          data: (
            <>
              <WebbDividerSmall />
              <h2 className='text-normal m-0 ms-3'>{'Featured Projects'}</h2>
              <Divider value={6} />
            </>
          )
        }}
        footer={{
          size: 'medium',
          show: true,
          data: (
            <>
              <div className='text-center back-color-lite'>
                <WebbFooterMobile />
              </div>
            </>
          )
        }}
      />
    </>
  )
}
