// reducers/transferReducer.js
function getTodayDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()+1).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function getSevenDaysAgoDate() {
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const year = sevenDaysAgo.getFullYear();
  const month = String(sevenDaysAgo.getMonth() + 1).padStart(2, '0');
  const day = String(sevenDaysAgo.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const initialState = {
    start: getSevenDaysAgoDate(),
    end: getTodayDate(),
    assets: true,
    funds: true,
    success: false,
    failed: false,
    pending: false,
    all: true,
    page:1
  };
  
  const transferReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_TRANSFER_DATA':
        return {
          ...state,
          ...action.payload
        };
        case 'UPDATE_PAGE':
          return {
            ...state,
            page: action.payload
          };
      default:
        return state;
    }
  };
  
  export default transferReducer;
  