// transfer
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { NumberFormat } from '../../services/srvc-utilities'

import WebbDividerSmall from '../webx/webb-divider-sm'

import NavsButtonBack from '../webx/navs-button-back'
import NavsButtonNext from '../webx/navs-button-next'

import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { GetUserForm } from '../../services/srvc-utilities'

import { TransfersAssetList } from '../../services/srvc-transfers-assets-realm'

const list = require('../../data.static/data-transfers-status.json').data 

export default function TransfersAssetListModule (props) {
  // console.log(props.search)

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([])
  const [filterdata, setFilterData] = useState([])

  const [search, setSearch] = useState()

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const datx = {
          creator: asset.item, 
          index: index, items: items, 
          filters:{ name: props.search }
        }
        
        const result = await TransfersAssetList({
          data: datx,
          client: asset?.creds?.client || '******',
          service: asset?.creds?.service || '******'          
        })
        // console.log (result)

        if (result.stat) {
          setData(result.data.list)
          setFilterData(result.data.list)

          const resTotal = result.data.count //+resAssets.data.count
          setTotal(result.data.count)

          setText(
            `${(index - 1) * items + 1} - ${
              index * items < resTotal ? index * items : resTotal
            } of ${resTotal}`
          )
        }

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [index, items])

  useEffect(() => {
    if (props.search && data) {
      const start = new Date(props.search.start).getTime()
      const end = new Date(props.search.end).getTime()
      const datax = data.filter(
        item => item.created >= start && item.created <= end
      )
      setFilterData(datax)
      setTotal(datax.length)
      setText(
        `${(index - 1) * items + 1} - ${
          index * items < datax.length ? index * items : datax.length
        } of ${datax.length}`
      )
    }
  }, [props.search])

  const NextIndex = async () => {
    if (data.length < items) {
    } else {
      setNextIndex(curr + 1)
      setIndex(curr + 1)
      setCurrentIndex(curr + 1)
    }
  }

  const LastIndex = async () => {
    if (index == 1) {
    } else {
      setLastIndex(curr - 1)
      setIndex(curr - 1)
      setCurrentIndex(curr - 1)
    }
  }

  if (!loader && (!data || data.length === 0))
  return <>No Asset Transfers</>

  return (
    <>
      <div className={loader ? 'd-none' : 'back-color-wite border rounded'}>
      
      {/* header */}
      <div className="border-bottom text-small text-bold">
        <div className="d-flex p-2 px-3">

          <div className="" style={{width: '5%'}}>         
            <p className="m-0">
              <span className="text-small">
                <i className="bx bxs-grid-alt text-color-tint"></i>
              </span>
            </p>
          </div>  

          <div className="" style={{minWidth: '55%'}}>
            <p className="m-0">
              <span className="">{'User'}</span>
            </p>
          </div>    

          <div className="" style={{width: '20%'}}>         
            <p className="m-0 text-sm">
              <span className="">{'Status'}</span>
            </p>
          </div>  

          <div className="ms-auto text-end text-sm" style={{width: '20%'}}>         
            <p className="m-0 text-sm ">
              <span className="d-none d-md-block">{'Amount'}</span>
              <span className="d-md-none">{'Status'}</span>
            </p>
          </div>

        </div>
      </div>

      {/* data */} 
      {data && data.map((item, i) => (
        <div key={i}>
          <div className="d-flex p-2 px-3">
          
            <div className="" style={{width: '5%'}}>         
              <p className="m-0">
                <span className="text-normal">
                  <i className="bx bxs-image text-color-tint"></i>
                </span>
              </p>
            </div>  

            <div className="" style={{width: '55%'}}>
              <p className="m-0">
                <span className="text-bold">{item.user.name}</span>
              </p>
              <p className="m-0 text-small">{item?.meta?.memo || '******'}</p>
              <p className="m-0 text-small">
                <span>{(new Date(parseInt(item?.created))).toLocaleString() || '******'}</span>
              </p>
            </div>
            <div className="" style={{width: '20%'}}>
              <span className={`p-1 rounded text-mini text-uppercase text-color-wite back-${list.find(x => x.code == item.status).color}`}>
                {list.find(x => x.code == item.status).memo}
              </span>
            </div>
            <div className="ms-auto text-end" style={{width: '20%'}}>
              <p className="m-0">
                <span className="ms-1">{item.mode =='credit' ? '+' : '-'}</span>
                <span className="">{item.size.number || '0'}</span>
                <span className="ms-1"></span>
                <span className="text-mini">{item.size.ticker || ''}</span>
              </p>
            </div>
          </div>
          <div className={i < data.length-1 ? 'border-bottom': ''}></div>
        </div>
      ))}
      
      </div>

      {/* navs */}
      <div className={loader ? 'd-none' : ''}>
        <WebbDividerSmall />
        <div className={data.length < items ? '' : ''}>
          <div className='d-flex justify-content-between'>
            <div className='' onClick={() => LastIndex()}>
              <NavsButtonBack />
            </div>

            <div className=''>
              <p className='my-3'>{text}</p>
            </div>

            <div className='' onClick={() => NextIndex()}>
              <NavsButtonNext />
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}
