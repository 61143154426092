// assets
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { NumberFormat } from '../../services/srvc-utilities'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { AssetDetails } from '../../services/srvc-assets-realm'

const list = [
  { name: 'Total', code: 'count', number: '', ticker: '', active: false },
  { name: 'Mint', code: 'mint', number: '', ticker: '', active: true },
  { name: 'Book', code: 'book', number: '', ticker: '', active: true },
  { name: 'Sale', code: 'sale', number: '', ticker: '', active: true },
  { name: 'Revoke', code: 'burn', number: '', ticker: '', active: true }
]

export default function AssetStatisticsModule (props) {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const { id } = useParams()

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState()
  const [units, setUnits] = useState()

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const datx = { item: props.data.item }
        const result = await AssetDetails({ data: datx, srvc: '******'})

        if (result.stat) {
          setUnits( Array.from(list, x => { return {
            ...x,
            number: result.data.units[x.code]
          }}))
        }

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [props])

  const handleClick = async item => {
    // navigate(`/${asset.role}/ax/${item}`)
  }

  if (loader)
    return (
      <>
        <div className='p-3 back-color-wite rounded-xd border align-middle' style={{}} >
          <span className='align-middle text-lead'>
            <i className='bx bxs-info-circle text-color-wait'></i>
          </span>
          <span className='ms-1 text-color-tone'>Please Wait...</span>
        </div>
      </>
    )

  return (
    <>

      {/* asset units */}
      <div className='back-color-wite rounded-xd p-3 border'>
        <div className='row row-cols-2 row-cols-md-4 g-3'>
          {units && units.map((item, i) => item.active ? (
            <div className='col' key={i}>
              <div className={`w-100 h-100 border-none m-0 p-0`}>
                <div className='d-flex'>
                  <div className=''>
                    <p className='text-mini m-0 text-uppercase'>{item.name}</p>
                    <p className='text-lead text-bold m-0'> {item.number || '0'}</p>
                  </div>
                  <div className='ms-auto '>
                    <i className={`text-icon-md text-color-tint ${item.icon}`} ></i>
                  </div>
                </div>
              </div>
            </div>
          ) 
          : (''))}
        </div>
      </div>
    </>
  )
}
