// content
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import { AssetUnitDetails } from "../../services/srvc-assets-units-realm";

const listactions = [
  { name: "Live", code: "live", status: false, active: true },
  { name: "Show", code: "show", status: false, active: true },

  { name: "Mint", code: "mint", status: false, active: false },

  { name: "Scan", code: "scan", status: false, active: true },
  { name: "Sale", code: "sale", status: false, active: false },
  { name: "Book", code: "book", status: false, active: true },
  { name: "Burned", code: "burn", status: false, active: false },

  { name: "Feature", code: "feat", status: false, active: false },
  { name: "Search", code: "srch", status: false, active: false },
];

export default function AssetStatusModule(props) {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        const datx = { item: props?.unit, creator: asset.item };
        const result = await AssetUnitDetails({
          data: datx,
          client: asset?.creds?.client || "******",
          service: asset?.creds?.service || "******",
        });
        // console.log (result)

        // console.log(result)
        if (result) {
          setCurrentItems(result);

          // send data
          // props.data(result.data);
        }

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);


  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const actionx = listactions.filter((x) => x.active);
        const result = Array.from(actionx, (x) => {
          return {
            ...x,
            status: props.data[x.code],
          };
        });
        setData(result);

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [props]);

  const handleClick = (live, link) => {
    if (live) navigate(`/${asset.role}/${link}`);
  };

  if (loader) return <></>;

  return (
    <>
      <div className="back-color-wite rounded-xd">
        {data &&
          data.map((item, i) => (
            <div className={`p-1 px-3`} key={i}>
              <div className="d-flex form-check form-switch m-0 p-0">
                <div className="">
                  <p className="m-0 p-0">
                    <span className="text-small align-middle">{item.name}</span>
                  </p>
                </div>
                <div className="ms-auto text-end">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    style={{ height: "1.2rem", width: "2rem" }}
                    checked={item.status}
                    value=""
                  ></input>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
