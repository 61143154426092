import React, { useState } from "react";
import * as XLSX from "xlsx";
import { AddBulkContacts } from "../../services/srvc-users-creators-realm";
import { useNavigate } from "react-router-dom";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { GetUserForm } from "../../services/srvc-utilities";

export default function NetworkAddBulkUser() {
  const navigate = useNavigate();

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const [file, setFile] = useState(null);
  const [loader, setLoader] = useState(false);

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    // Check if the file type is Excel
    if (
      uploadedFile &&
      (uploadedFile.type === "application/vnd.ms-excel" ||
        uploadedFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      setFile(uploadedFile);
    } else {
      alert("Please upload an Excel file.");
    }
  };

  const handleUpload = async () => {
    setLoader(true);

    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming the data is in the first sheet
      const sheet = workbook.Sheets[workbook.SheetNames[0]];

      // Convert sheet data to JSON
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      const requiredFields = ["name", "mail", "mobile"];
      const missingFields = requiredFields.filter(
        (field) => !Object.keys(jsonData[0]).includes(field)
      );

      if (missingFields.length > 0) {
        alert(`Required fields are missing: ${missingFields.join(", ")}`);
        setLoader(false);
        return;
      }

      // Validate mobile numbers and email addresses
      const invalidEntries = [];
      jsonData.forEach((entry, index) => {
        if (!/^\d+$/.test(entry.mobile)) {
          invalidEntries.push(
            `Row ${index + 2}: Mobile number must be numeric`
          );
        }
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(entry.mail)) {
          invalidEntries.push(`Row ${index + 2}: Invalid email address`);
        }
      });

      if (invalidEntries.length > 0) {
        alert(`Invalid data:\n${invalidEntries.join("\n")}`);
        setLoader(false);
        return;
      }

      // Proceed with sending jsonData to API endpoint
      var res = await AddBulkContacts({
        data: {
          team: asset.item,
          list: jsonData,
        },
        srvc: '******'
      });

      if (res) {
        setLoader(false);
        alert(res.memo)
        navigate(`/team/network`);
      }
    };
    setLoader(false);

    reader.readAsArrayBuffer(file);
  };

  if (loader) {
    return (
      <div
        className="p-3  rounded-xd d-flex align-items-center  justify-content-center "
        style={{ marginTop: "200px" }}
      >
        <p className="P-4 m-0 mx-4 fw-bold">Please wait ....</p>
        <div class="spinner-border" role="status"></div>
      </div>
    );
  }

  return (
    <div className="container">
      <div
        className="form-container"
        style={{
          margin: "50px auto",
          backgroundColor: " #ffffff",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: " 0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="form-group my-3">
          <label htmlFor="username">Choose XL file:</label>
          <input
            type="file"
            className="form-control mt-2"
            id="file"
            placeholder="choose file"
            onChange={handleFileChange}
            style={{ width: "100%" }}
          />
        </div>

        <div className="d-flex mt-3">
          <div className="me-auto"></div>
          <div className="text-end">
            <button
              type="button"
              className="btn btn-primary rounded-xx "
              onClick={handleUpload}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
