// main
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import ContentFormat from '../content/webz/content-format-xv'
import WebbHeader from '../content/webz/webb-header-navs-xv'

import WebbIcon from '../content/webx/webb-icon'
import UserAvatar from '../content/webx/user-avatar'

import { GetLocalBusiness, GetLocalUser } from '../services/srvc-auth-local'
import { GetUserForm } from '../services/srvc-utilities'

import AssetsSearchModule from '../content/assets/assets-search'
import AssetsListCreatorModule from '../content/assets/assets-list-creator'

import AssetActionsModule from '../content/assets/assets-actions-creator'


import { Divider } from '../views.utils/utils'


export default function Assets () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  

  const metadata = {
    name: 'Assets',
    banner: { link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }
  
  return (
    <>
      <Helmet>
        <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
        <link rel='canonical' href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: 'xtra', show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`${asset.role}/home`}>
                <WebbIcon data={{ color: 'text-color-main', size: 'text-icon-sm' }} />
              </Link>
            </>
          ),
          data: ( <> <WebbHeader /> </> ),
          footer: ( <> <UserAvatar /> </> )
        }}
        sidebar={{
          header: ( <> <p className='text-normal text-bold m-0'>{metadata.name}</p> </>),
          data: (
            <>
              <AssetsSearchModule  />
            </>
          ),
          footer: <>...</>
        }}
        content={{
          header: ( <> <p className='m-0'>Assets List</p> </> ),
          data: (
            <>
              <h2 className='text-normal text-color-next mx-3 mb-2 d-none'>Assets </h2>
              <AssetsListCreatorModule />
              <Divider value={6} />
            </>
          )
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: ( <> 
            <AssetActionsModule /> 
            
          </> ),
          footer: <></>
        }}
      />
    </>
  )
}
