export const STATUS_CODES = [
  {
    code: 0,
    memo: 'created',
    icon: 'bx bxs-circle',
    color: 'text-color-tint',
    text: 'warning'
  },
  {
    code: 1,
    memo: 'active',
    icon: 'bx bxs-circle',
    color: 'text-color-wait',
    text: 'warning'
  },
  {
    code: 2,
    memo: 'scheduled',
    icon: 'bx bxs-info-circle',
    color: 'text-color-wait',
    text: ''
  },
  {
    code: 3,
    memo: 'locked / on-hold',
    icon: 'bx bxs-minus-circle',
    color: 'text-color-wait',
    text: ''
  },
  {
    code: 4,
    memo: 'cancelled',
    icon: 'bx bxs-x-circle',
    color: 'text-color-error',
    text: 'danger'
  },
  {
    code: 5,
    memo: 'timeout',
    icon: 'bx bxs-error-circle',
    color: 'text-color-error'
  },
  {
    code: 6,
    memo: 'closed (success)',
    icon: 'bx bxs-check-circle',
    color: 'text-color-success'
  },
  {
    code: 7,
    memo: 'declined (user)',
    icon: 'bx bxs-x-circle',
    color: 'text-color-error'
  },
  {
    code: 8,
    memo: 'revoked (user)',
    icon: 'bx bxs-x-circle',
    color: 'text-color-error'
  },
  {
    code: 9,
    memo: 'declined (user)',
    icon: 'bx bxs-right-arrow-circle',
    color: 'text-color-next'
  }
]
export const StatusBadge = ({ code }) => {
  const status = STATUS_CODES.find(x => x.code === code || 0)
  return (
    <span className={`text-small me-2 ${status.color}`}>
      <i className={`text-small bx ${`bxs-circle text-${status.icon}`}`}></i>
    </span>
  )
}

export const DispalyLoder = ({ msg }) => {
  return (
    <div
      className='p-3 back-color-wite rounded-xd border align-middle'
      style={{}}
    >
      <span className='align-middle text-lead'>
        <i className='bx bxs-info-circle text-color-wait'></i>
      </span>
      <span className='ms-1 text-color-tone'>{msg || 'Please Wait...'}</span>
    </div>
  )
}
