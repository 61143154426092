
export const updateAssetData = (data) => ({
    type: 'UPDATE_ASSET_DATA',
    payload: data,
  });
  
  export const updateAssetSearchTerm = (search) => ({
    type: 'UPDATE_ASSETSEARCH_TERM',
    payload: search,
  });
  
  export const updateAssetSearchButton = (searchButton) => ({
    type: 'UPDATE_ASSETSEARCH_BUTTON',
    payload: searchButton,
  });
  export const updatePageAsset = (page) => ({
    type: 'UPDATE_ASSET_PAGE',
    payload: page,
  });