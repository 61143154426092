// content
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {QRCodeCanvas} from 'qrcode.react';

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { AssetUnits } from '../../services/srvc-assets-realm'

export default function AssetCodeModule (props) {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const { id } = useParams()

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState()

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        setData(props?.data || {id: id})
        // console.log(props.data)
        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [props])

  const handleClick = (item) => {
    // window.location.)
    // if (live) navigate(`/${asset.role}/${link}`)
  }

  if (loader) return <></>

  return (
    <>
      <div className="back-color-wite text-center p-3 rounded-xd"
        // onClick={() => handleClick(data.id)}
      >
        <a className='cursor' href = {`https://minx.bharat.id/sx/${data.id}`} target='_blank' rel="noopener">
          <QRCodeCanvas value={`iam.asset.${data.id}`} size={'128'} includeMargin={false} />
        </a>
        
      </div>
      
    </>
  )
}
